import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { calculateAge } from '@/utils';
import { Allocation, AllocationType, AllocationStateValues, AllocationConcludedReasonCodes } from '@/store/allocations/types';
import { OrganCodeValue, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';
import { DeceasedDonor, DeceasedDonorsState, DeceasedDonorOrgan, AvailableAllocationType, DeceasedDonorOrganDonations, DeceasedDonorAllocationSummary, DonorSignificantEvent, DonorSignificantEventAttributeChanged, SignificantEventAttributeEntry, DonorSignificantAttributes, DeceasedDonorOrganDonationOffer } from '@/store/deceasedDonors/types';
import { EP } from '@/api-endpoints';

// Private functions

/**
 * Do we need to show an allocation summary for the specified donor and organ consent?
 *
 * NOTE: we are relying on this to handle per-allocation permission requirements
 *
 * @param donor the deceased donor we are checking
 * @param organConsent an organ consent from that donor to check
 * @param allocationSummaryType which type of allocation we are trying to summary
 * @returns {boolean} true only if organ allocation should be shown
 */
function isConsentedOrganAllocationApplicable(extendedPermissions: boolean, donor: DeceasedDonor, organConsent: DeceasedDonorOrgan, allocationSummaryType?: string, allowedPostPermission?: boolean): boolean {
  // Non-Kidney organs, there is only one type of allocation and it has no allocation type;
  if (organConsent.organ_code !== OrganCodeValue.Kidney) return !allocationSummaryType;

  // For Kidney there are 'Local' and 'Provincial' allocation types
  // The allocation type must be both possible and available to be applicable

  // Whether or not allocation type is 'possible' depends on donor-level indicators
  const doubleKidney = donor.indicators?.double_kidney || false;
  const oopDonor = donor.indicators?.out_of_province || false;
  const isLocalPossible = !oopDonor;
  const isProvincialPossible = oopDonor ? true : !doubleKidney;

  // load available and runnable allocation types & de-duplicate data
  const availableAllocationTypes = organConsent.available_allocation_types ? [...new Set(organConsent.available_allocation_types.map((availableAllocationType: AvailableAllocationType): string => { return availableAllocationType.allocation_type; }))] : [];
  const runnableAllocationTypes = organConsent.runnable_allocation_types ? [...new Set(organConsent.runnable_allocation_types)] : [];

  // based on whether the user is allowed to access the donors index page. If yes then they
  // get access to runnable_allocation_types, otherwise they use available_allocation_tyoes
  // e.g. just local or both local & provincial.
  const allowedAllocationTypes = extendedPermissions ? runnableAllocationTypes : availableAllocationTypes;

  const isLocalAvailable = allowedAllocationTypes.includes(AllocationType.Local);
  const isProvincialAvailable = allowedAllocationTypes.includes(AllocationType.Provincial);

  // Return result based on specified summary type
  if (allocationSummaryType === AllocationType.Local) return isLocalPossible && isLocalAvailable;
  if (allocationSummaryType === AllocationType.Provincial) return isProvincialPossible && isProvincialAvailable;

  // If we get this far, then NO the organ allocation is NOT applicable
  return false;
}

// Getters
export const getters: GetterTree<DeceasedDonorsState, RootState> = {
  show(state): DeceasedDonor|undefined {
    return state.selected || {};
  },
  showList(state) {
    return state.donorsList;
  },
  deceasedDonorDisplayName(state): string {
    if (state.selected && state.selected.patient_profile) {
      return [state.selected.patient_profile.first_name, state.selected.patient_profile.last_name].join(' ');
    } else {
      return '';
    }
  },
  /**
   * Checks if we can calculate the donor age, if yes calculate age otherwise return static value
   *
   * @returns {number|undefined} age based on calculation or static value
   */
  getDonorAge(state, getters, rootState, rootGetters): number|undefined {
    const donor = state.selected || {};
    const editDoB = rootState.pageState?.currentPage?.referralInformation?.date_of_birth || undefined;
    const dob = donor.patient_profile?.birth?.date || undefined; // get date of birth
    const fromItransplant = getters.donorFromItransplant; // donor from itransplant
    const deathDate = getters.getDeathDateWithFallbacks;
    const isSurgicalUser = rootGetters["users/isSurgicalUser"];
    // if itransplant or age available display it
    if (fromItransplant || isSurgicalUser) {
      // if from itransplant, don't calculate age
      return donor.patient_profile?.age === 0 ? 0 : donor.patient_profile?.age || undefined;
    } else {
      // if from oats, calculate age
      // if death date found, return age otherwise return undefined
      return deathDate ? calculateAge(editDoB || dob, deathDate) : undefined;
    }
  },
  /**
   * Checks if we can calculate the donor age, if yes calculate age otherwise return static value
   *
   * @returns {number|undefined} age based on calculation or static value
   */
   getNewDonorAge(state, getters, rootState, rootGetters): number|undefined {
    const editDoB = rootState.pageState?.currentPage?.referralInformation?.date_of_birth || undefined;
    const deathDate = getters.getDeathDateWithFallbacks;
    // if have death date, calculate age
    if (deathDate) {
      return calculateAge(editDoB, deathDate); // if death date found, return age
    } else {
      return undefined; // otherwise return undefined
    }
  },
  /**
   * Returns age based on donor details
   *
   * @returns {number|undefined} age based on date - current date
   */
   getDeathDateWithFallbacks(state, getters, rootState, rootGetters) {
    const donor = state.selected || {};

    if (!donor) { return undefined; } // if no donor, return undefined

    const crossClampDate = donor.organ_retrieval_surgery?.cross_clamp_date ? donor.organ_retrieval_surgery.cross_clamp_date : undefined; // TODO: will be added in tp-8756
    const secondDeclarationDate = donor.death?.second_declare_date ? donor.death.second_declare_date : undefined;
    const firstDeclarationDate = donor.death?.first_declare_date ? donor.death.first_declare_date : rootState.pageState?.currentPage?.clinicalInformation?.declarationDate;
    const referralDate = donor.referral && donor.referral.referral_date ? donor.referral.referral_date : rootState.pageState?.currentPage?.referralInformation?.referral_date;

    if (crossClampDate) { return crossClampDate; } // use cross clamp date
    if (secondDeclarationDate) { return secondDeclarationDate; } // use second declaration date
    if (firstDeclarationDate) { return firstDeclarationDate; } // use first declaration date
    if (referralDate) { return referralDate; } // use referral date

    return undefined; // otherwise return undefined
  },
  buildAllocationSummaryItem(state, getters, rootState, rootGetters): (consentedOrgan: DeceasedDonorOrgan, option?: string) => DeceasedDonorAllocationSummary {
    return (consentedOrgan: DeceasedDonorOrgan, option?: string): DeceasedDonorAllocationSummary => {
      // Donor details / Indicators / Double Kidney
      const selectedDonor = state.selected || {};
      const donorIndicators = selectedDonor.indicators || {};
      const organCount = donorIndicators.double_kidney ? 'Double ' : '';
      // Get details from Consented Organ data model itself
      const organ_code = consentedOrgan.organ_code || 0;
      const organName = rootGetters['lookups/lookupValue'](consentedOrgan.organ_code, 'organ');
      const availableAllocationType = consentedOrgan.available_allocation_types?.find((aat) => {
        if (consentedOrgan.organ_code !== OrganCodeValue.Kidney) return true;

        return aat.allocation_type == (option ? option : AllocationType.Local);
      });

      // Get details about Allocation
      let allocationInfo;
      if (availableAllocationType){
        allocationInfo = {
          allocationId: availableAllocationType.client_id,
          allocationStage: getters.describeAllocationStage(availableAllocationType),
          allocationRunDateTime: availableAllocationType.start_date,
        };
      } else {
        // On reloading allocation summary, allocation info can be extracted from allocation instead of directly to consentedOrgan
        const organAllocation = rootGetters['allocations/getAllocationInfoByConsentedOrgan'](consentedOrgan, option, donorIndicators.double_kidney);
        allocationInfo = organAllocation ? {
          allocationId: organAllocation.client_id,
          allocationStage: getters.describeAllocationStage(organAllocation),
          allocationRunDateTime: organAllocation.start_date
        } : {};
      }
      // Setup display name and routing options
      let display_name: string;
      let route: any;
      if (consentedOrgan.organ_code === OrganCodeValue.Kidney) {
        const describeOption = option ? option : '';
        display_name = `${organCount}${organName || ''} (${describeOption})`;
        // If this is a double_kidney replace the option param with 'double'
        const optionParam = donorIndicators.double_kidney ? 'double' : option;
        route = { name: 'deceased-donor-organ-option', params: { organ_code, option: optionParam } };
      } else {
        display_name = organName || '';
        route = { name: 'deceased-donor-organ', params: { organ_code } };
      }

      // Return our donor summary item
      return {
        ...consentedOrgan,
        ...allocationInfo,
        organ: organName,
        display_name,
        route,
      };
    };
  },
  /**
   * Generate a string representation of the 'Stage' of an Allocation
   *
   * This is roughly based on the 'State' value of the Allocation itself, but in some situations
   * the webapp shows something else instead e.g. "Transplanted".
   *
   * NOTE: the 'Transplanted' special case is why this getter is in the 'deceasedDonors' module,
   * because in this situation the Organ Donation data can be what determines the visual 'Stage'.
   *
   * @returns {(allocation: Allocation => string} function to generate visual 'Stage' values
   */
  describeAllocationStage(state, getters): (allocation: Allocation) => string {
    return (allocation): string => {
      if (!allocation) return '-';

      // Check special cases that 'override' allocation state for display text
      const isOrganTransplanted = getters.allocationTransplanted(allocation);
      if (isOrganTransplanted) return 'organ_donation_status.transplanted';

      return `allocation_stage.${allocation.state || 'unknown'}`;
    };
  },
  /**
   * Return true if the Allocation is Transplanted
   *
   * This will use an Allocation client_id and find the right entry in the
   * selectedDonor's organ_donation array.  From there we check to see if
   * the organ_donation has a corresponding transplant_date value, if so we
   * consider the Allocation as Transplanted.
   *
   * @param allocation the allocation to check
   * @returns {boolean} true if the Allocation is Transplanted
   */
  allocationTransplanted(state): (allocation: Allocation) => boolean {
    return (allocation: Allocation): boolean => {
      if (allocation.state != AllocationStateValues.Concluded) return false;

      return (allocation.concluded_reason_code == AllocationConcludedReasonCodes.OrganTransplanted);
    };
  },

  /*
   * Build a list of deceased donor organ allocation 'summary' items
   *
   * NOTE: this is used for per-allocation details in donor header/summary bar
   *
   * @returns {DeceasedDonorAllocationSummary[]} array of organ allocation summaries
   */
  selectedDonorConsentedOrganList(state, getters, rootState, rootGetters): DeceasedDonorAllocationSummary[] {
    // If no donor, there are no organ consents
    if (!state.selected) return [];

    // Return empty array if organ_consents not defined
    const donor: DeceasedDonor = state.selected;
    const organConsents: undefined|DeceasedDonorOrgan[] = donor.organ_consents;
    if (!organConsents || organConsents.length === 0) return [];

    // Explicitly only handle organ_consents with consented: true
    const consentedOrganConsents: DeceasedDonorOrgan[] = organConsents.filter((item: DeceasedDonorOrgan) => { return item.consented === true; });

    // Iterate through consented entries in organ_consents to generate allocation summaries
    const result: DeceasedDonorAllocationSummary[] = [];
    consentedOrganConsents.forEach((consentedOrganConsent) => {
      // determine whether current user has access to create a new allocation (determine whether csc or surgical user)
      // We actually are checking for "index" access but the "index" endpoint includes pagination fields so for simplicity
      // "create" is being used because it uses the same resource path with a different verb.
      const extendedPermissions = rootGetters['users/checkAllowed'](EP.deceasedDonors.create, "GET");

      // Each non-kidney organ consent maps to an allocation page 1-to-1, without any allocation_type value
      const isUntypedAllocationApplicable = isConsentedOrganAllocationApplicable(extendedPermissions, donor, consentedOrganConsent);

      // Kidney organ consent can map to 0, 1, or 2 allocations, dependong on donor-level and permission-based factors
      const isLocalAllocationApplicable = isConsentedOrganAllocationApplicable(extendedPermissions, donor, consentedOrganConsent, AllocationType.Local);
      const isProvincialAllocationApplicable = isConsentedOrganAllocationApplicable(extendedPermissions, donor, consentedOrganConsent, AllocationType.Provincial);

      // For each applicable type of allocation, generate an allocation summary and push it to result array
      if (isUntypedAllocationApplicable)    result.push(getters.buildAllocationSummaryItem(consentedOrganConsent));
      if (isLocalAllocationApplicable)      result.push(getters.buildAllocationSummaryItem(consentedOrganConsent, AllocationType.Local));
      if (isProvincialAllocationApplicable) result.push(getters.buildAllocationSummaryItem(consentedOrganConsent, AllocationType.Provincial));
    });

    return result;
  },

  donorOrganPackagingForms(state, getters, rootState, rootGetters): any[] {
    if (!state.selected) {
      return [];
    }
    const organDonations: undefined|DeceasedDonorOrganDonations[] = state.selected.organ_donations;
    if (!organDonations || organDonations.length < 0) {
      return [];
    }
    const organDonationForms: any = organDonations.map((item) => {
      const organName = rootGetters['lookups/lookupValue'](item.organ_code, 'organ');
      const organOffer: DeceasedDonorOrganDonationOffer = item.organ_offer || {};
      const organRecovery = item?.organ_recovery && item?.organ_recovery.length > 0 ? item?.organ_recovery[0] : {};
      const destinationIdentifier = organRecovery?.destination_program_identifier;
      const destinationProgramId = organRecovery?.destination_program?.$oid;
      const hospital = rootGetters['hospitals/getHospitalById'](destinationProgramId);
      return {
        organDonationId: item._id?.$oid,
        organCode: item.organ_code,
        organName: organName,
        organSpecificationCode: item.organ_specification_code,
        allocationClientId: organOffer.allocation_client_id,
        transplantProgram: destinationIdentifier || hospital?.hospital_name_info?.abbreviation,
        recipientClientId: organOffer.recipient_client_id
      };
    });
    return organDonationForms;
  },
  clientId(state): string|undefined {
    if (state.selected && state.selected.client_id) {
      return state.selected.client_id.toString();
    } else {
      return '';
    }
  },
  new(): DeceasedDonor {
    return {
      _id: { $oid: '' },
      measurements: [],
      blood: {},
    };
  },
  organRecoveryRowData(): {position: number, organ_code: number, organ_specification_code: number|null}[]  {
    const rows = [
      { position: 1,  organ_code: OrganCodeValue.Heart,          organ_specification_code: null },
      { position: 2,  organ_code: OrganCodeValue.Lung,           organ_specification_code: OrganOfferSpecificationCodeValue.LeftLung },
      { position: 3,  organ_code: OrganCodeValue.Lung,           organ_specification_code: OrganOfferSpecificationCodeValue.RightLung },
      { position: 4,  organ_code: OrganCodeValue.Lung,           organ_specification_code: OrganOfferSpecificationCodeValue.BothLungs, },
      { position: 5,  organ_code: OrganCodeValue.Kidney,         organ_specification_code: null },
      { position: 6,  organ_code: OrganCodeValue.Liver,          organ_specification_code: OrganOfferSpecificationCodeValue.LeftLiver },
      { position: 7,  organ_code: OrganCodeValue.Liver,          organ_specification_code: OrganOfferSpecificationCodeValue.RightLiver },
      { position: 8,  organ_code: OrganCodeValue.Liver,          organ_specification_code: OrganOfferSpecificationCodeValue.WholeLiver },
      { position: 9,  organ_code: OrganCodeValue.PancreasIslets, organ_specification_code: null },
      { position: 10, organ_code: OrganCodeValue.PancreasWhole,  organ_specification_code: null },
      { position: 11, organ_code: OrganCodeValue.SmallBowel,     organ_specification_code: null },
      { position: 12, organ_code: OrganCodeValue.VCA,            organ_specification_code: null },
    ];
    return rows;
  },
  lookupDonorAge(state, getters, rootState, rootGetters): any {
    if (!state.selected) {
      return;
    }
    const isDonorFromItrx = state.selected && state.selected.indicators ? state.selected.indicators.from_itransplant : null;

    if (isDonorFromItrx && state.selected.patient_profile) {
      return state.selected.patient_profile.age;
    } else {
      const donorDoB = state.selected.patient_profile && state.selected.patient_profile.birth ? state.selected.patient_profile.birth.date : null;
      const donorDoD =
      state.selected.organ_retrieval_surgery ? state.selected.organ_retrieval_surgery.life_support_withdrawal_date
        : null;
    // I have a birth and death date
    if (donorDoB && donorDoD) {
      return calculateAge(donorDoB, rootGetters['utilities/parseDateUi'](donorDoD));
    }
    // I have a birth date only
    if (donorDoB && !donorDoD) {
      return calculateAge(donorDoB);
    }
    }


    return null;
  },
  describeDonorSignificantChange() {
    return (item: DonorSignificantEvent): string => {
      let isSignificantChange = false;
      const attributeChangedEvent = item as DonorSignificantEventAttributeChanged;
      isSignificantChange = (attributeChangedEvent?.changed_fields || []).some((field: SignificantEventAttributeEntry) => {
        if (field.changed_field && DonorSignificantAttributes.includes(field.changed_field)) return true;
      });
      const result = isSignificantChange ? 'Y' : 'N';
      return result;
    };
  },
  describeDonorEventSource() {
    return (item: DonorSignificantEvent): string => {
      // System-created events (from iTransplant or CTR) will not have user information
      const isSystemUser = !item || !item?.user || !item?.user?.user_id;
      // Show created_by value for system events, or 'Direct Entry' if there is user info
      return isSystemUser ? (item?.created_by || 'Unknown') : 'Direct Entry';
    };
  },
  describeDonorEventCoordinator() {
    return (item: DonorSignificantEvent): string => {
      const firstName = item?.user?.first_name;
      const lastName = item?.user?.last_name;
      const result = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '-';
      return result.trim();
    };
  },
  describeDonorEventChanges() {
    return (item: DonorSignificantEvent): string => {
      const attributeChangedEvent = item as DonorSignificantEventAttributeChanged;
      const changes = (attributeChangedEvent?.changed_fields || []).map((field: SignificantEventAttributeEntry) => {
        return field.changed_field;
      });
      return changes.join(', ');
    };
  },
  // Is the selected deceased donor indicated as from out-of-province?
  isOutOfProvince(state) {
    if (!state.selected) return false;

    return state.selected?.indicators?.out_of_province || false;
  },
  donorFromItransplant(donor): boolean {
    const isDonorFromItrx = donor.selected && donor.selected.indicators ? donor.selected.indicators.from_itransplant : false;
    return isDonorFromItrx ? isDonorFromItrx : false;
  }
};
