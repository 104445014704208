
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';
import { State, Getter } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { Allocation, AllocationRecipient, Transplant } from '@/store/allocations/types';
import { OrganCodeValue, OrganSpecification, Gender } from '@/store/lookups/types';
import { DeceasedDonor, DeceasedDonorOrganDonations, TransplantedOpoRecipient } from '@/store/deceasedDonors/types';

interface OpoTransplantDetailsState {
  display: {
    opoCode: string;
    opoName: string;
    province: string;
    country: string;
    offeredOrgan: string;
    offerTime: string;
    offerResponse: string;
    offerResponseTime: string;
  };
  input: {
    donorId: string|null;
    recipientAge: number|null;
    recipientGender: string|null;
    transplantDate: string|null;
    transplantType: number|null;
    comment: string|null;
  };
}

const TRANSPLANT_TYPE_ORGANS = [
  OrganCodeValue.Liver,
  OrganCodeValue.Kidney,
  OrganCodeValue.Lung,
];

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    NumberInput,
    ModalSection,
    TextAreaInput,
  }
})
export default class OpoTransplantDetailsModal extends mixins(DateUtilsMixin, ValidationUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.opoTransplantDetails) private editState!: OpoTransplantDetailsState;

  @Getter('sexOptions', { namespace: 'lookups' }) genderOptions!: Gender[];
  @Getter('clientId', { namespace: 'deceasedDonors' }) donorId!: string;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string;
  @Getter('selectedAllocation', { namespace: 'allocations' }) allocation!: Allocation;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalCode?: string|null) => Hospital|null;
  @Getter("transplantTypeLookup", { namespace: "lookups" }) transplantTypeLookup!: (organCode: number | string | undefined) => OrganSpecification[] | undefined;

  @Prop({ required: true }) allocationRecipientEntry!: AllocationRecipient|null; // Recipient with offer details to show in read-only fields

  // PRIVATE ATTRIBUTES

  private isSaving = false;

  // COMPONENT GETTERS

  // Which organ code is in the route parameters?
  get organId(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  // What is the Object ID string for the Allocation we're viewing?
  get allocationId(): string {
    return this.allocation?._id || '';
  }

  // Which one of the Donor's Organ Donations is associated with this Allocation?
  get allocatedOrganDonation(): DeceasedDonorOrganDonations|null {
    if (!this.allocationId || !this.donor) return null;
    
    const organDonations: DeceasedDonorOrganDonations[] = this.donor.organ_donations || [];
    const organDonation = organDonations.find((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation?.organ_offer?.allocation_id?.$oid === this.allocationId;
    });

    return organDonation || null;
  }

  // What options should we show in the UI-only Donor ID dropdown?
  get donorIdOptions(): GenericCodeValue[] {
    if (!this.donor) return [];

    const deceasedDonorId = this.donor.deceased_donor_id;
    return [
      {
        code: this.donorId,
        value: deceasedDonorId ? deceasedDonorId.toString() : '',
      },
    ];
  }

  // Do we need to show the Transplant Type field?
  get showTransplantType(): boolean {
    if (!this.allocation) return false;

    const organCode = this.allocation?.organ_code;
    return TRANSPLANT_TYPE_ORGANS.includes(organCode);
  }

  // What options should we show in the Transplant Type dropdown?
  get transplantTypeOptions(): OrganSpecification[] {
    if (!this.allocation) return [];

    const organCode = this.allocation?.organ_code;
    return this.transplantTypeLookup(organCode) || [];
  }

  // PRIVATE METHODS

  // Clear field-level validation errors from validation observer
  private resetValidationErrors(): void {
    const validationObserver =this.$refs.opoTransplantDetailsValidations as any;
    if (validationObserver) validationObserver.reset();
  }

  // Commit UI form interface data to edit state based on selected API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'opoTransplantDetails',
      value: this.buildOpoTransplantDetailsState(),
    });
  }

  // Generate form state based on component edit state interface
  private buildOpoTransplantDetailsState(): OpoTransplantDetailsState {
    // First setup empty defaults
    const result: OpoTransplantDetailsState = {
      display: {
        opoCode:           '-',
        opoName:           '-',
        province:          '-',
        country:           '-',
        offeredOrgan:      '-',
        offerTime:         '-',
        offerResponse:     '-',
        offerResponseTime: '-',
      },
      input: {
        donorId:           null,
        recipientAge:      null,
        recipientGender:   null,
        transplantDate:    null,
        transplantType:    null,
        comment:           null,
      },
    };

    // Populate read-only display fields based on Offer Details from Allocation
    const allocationRecipient = this.allocationRecipientEntry;
    const offer = allocationRecipient ? allocationRecipient.offer : null;
    if (allocationRecipient && offer) {
      const hospital = this.getHospitalById(allocationRecipient?.hospital_id);
      Object.assign(result, {
        display: {
          opoCode:           allocationRecipient?.hospital_abbreviation || '-',
          opoName:           hospital?.hospital_name_info?.name || '-',
          province:          this.lookupValue(allocationRecipient?.province_code, 'province') || '-',
          country:           this.lookupValue(allocationRecipient?.country_code, 'country') ||  '-',
          offeredOrgan:      this.organName(allocationRecipient?.organ_code) || '-',
          offerTime:         this.parseDisplayDateTimeUiFromDateTime(offer.datetime_offered) || '-',
          offerResponse:     this.lookupValue(offer?.response_code || undefined, 'offer_responses') || '-',
          offerResponseTime: this.parseDisplayDateTimeUi(offer.response_date) || '-',
        },
      });
    }

    // Populate editable input fields based on Organ Donation data from Donor
    const organDonation = this.allocatedOrganDonation;
    const opoRecipient = organDonation?.transplanted_opo_recipient;
    if (organDonation && opoRecipient) {
      Object.assign(result, {
        input: {
          donorId:         this.donorId,
          recipientAge:    opoRecipient.age != null ? opoRecipient.age : null,
          recipientGender: opoRecipient.gender || null,
          transplantDate:  this.parseDateUi(organDonation.transplanted_date) || null,
          transplantType:  organDonation.transplanted_organ_specification_code || null,
          comment:         opoRecipient.comments || null,
        },
      });
    }
    return result;
  }

  // Open modal section
  private openModalSection(): void {
    const targetModal = this.$refs.opoTransplantDetailsModalSection as ModalSection;
    if (targetModal) targetModal.toggleStaticModal();
  }

  // Close modal section
  private closeModalSection(): void {
    const targetModal = this.$refs.opoTransplantDetailsModalSection as ModalSection;
    if (targetModal) targetModal.hideModal();
  }

  // Show saving indicator and reset field-level validation errors
  private startSaving(): void {
    this.resetValidationErrors();
    this.isSaving = true;
  }

  // Dismiss saving indicator
  private stopSaving(): void {
    this.isSaving = false;
  }

  // Dispatch save request and and handle the results
  private saveOpoTransplantDetails(): void {
    this.startSaving();
    this.$store.dispatch('allocations/saveOpoTransplantDetails', {
      donorId: this.donorId,
      organId: this.organId,
      allocationId: this.allocationId,
      organDonation: this.extractOrganDonationPatch(),
    }).then((successResult: SaveResult) => {
      this.stopSaving();
      this.handleSuccessResult(successResult);
    }).catch((errorResult: SaveResult) => {
      this.stopSaving();
      this.handleErrorResult(errorResult);
    });
  }

  // Generate request payload based on back-end types
  private extractOrganDonationPatch(): DeceasedDonorOrganDonations {
    const form = this.editState?.input;
    const result: DeceasedDonorOrganDonations = {
      // as per tp-13998 , transplant time should be defaulted to 23:59:59
      transplanted_date:                     form.transplantDate ? this.sanitizeDateTimeApi(form.transplantDate,Transplant.TransplantTime) : null,  
      transplanted_organ_specification_code: form?.transplantType || null,
      transplanted_opo_recipient: {
        age:                                 form?.recipientAge != null ? form?.recipientAge : null,
        gender:                              form?.recipientGender || null,
        comments:                            form?.comment || null,
      },
    };
    return result;
  }

  // Close the popup and request the view reload Donor document
  private handleSuccessResult(_result: SaveResult): void {
    this.closeModalSection();
    this.reload();
  }

  // Bubble up a request to the view that the Donor data needs reloaded
  private reload(): void {
    this.$emit('reload');
  }

  // Show field-level validation errors from invalid save request
  private handleErrorResult(result: SaveResult): void {
    const idLookup: IdLookup = this.idLookup();

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(result, idLookup);

    const validationObserver = this.$refs.opoTransplantDetailsValidations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  // PUBLIC METHODS

  // Reset, initialize, and show popup modal form
  public openModal(): void {
    this.resetValidationErrors();
    this.initializeForm();
    this.openModalSection();
  }

  // Map from field-level validation error key to template element IDs
  // Note: right-hand side is an array, in case an error should appear in multiple places
  public idLookup(): IdLookup {
    return {
      "opo_transplant_details.transplanted_date":                     ["opo-transplant-transplant-date"],
      "opo_transplant_details.transplanted_opo_recipient.age":        ["opo-transplant-recipient-age"],
      "opo_transplant_details.transplanted_opo_recipient.comments":   ["opo-transplant-comment"],
      "opo_transplant_details.transplanted_opo_recipient.gender":     ["opo-transplant-recipient-gender"],
      "opo_transplant_details.transplanted_organ_specification_code": ["opo-transplant-transplant-type"],
    };
  }
}
