
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GenericCodeValue } from '@/store/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { LivingAllocationRecipient, LivingAllocationOfferTypeValues, LivingAllocationResponse, LivingAllocationOffer } from '@/store/livingAllocations/types';

interface AllocationOfferHistoryPageState {
  title: string,
  allocationId: string,
  records: LivingAllocationRecipient[]
}

@Component({
  components: {
    ModalSection,
    VueGoodTable
  }
})
export default class OfferHistoryModal extends mixins(DateUtilsMixin) {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.currentUser) private currentUser!: any;

  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: any;
  @Getter('primaryOffers', { namespace: 'livingAllocations' }) private primaryOffers!: LivingAllocationRecipient[];
  @Getter('backupOffers', { namespace: 'livingAllocations' }) private backupOffers!: LivingAllocationRecipient[];
  @Getter('noOffers', { namespace: 'livingAllocations' }) private noOffers!: LivingAllocationRecipient[];
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('offerResponseCode', { namespace: 'lookups' }) private offerResponseCode!: (code: string|null) => string;
  @Getter('noOfferReasonCategory', { namespace: 'lookups' }) private noOfferReasonCategory!: (code: string) => string;
  @Getter('noOfferReasonCode', { namespace: 'lookups' }) private noOfferReasonCode!: (category: string, code: string) => string;
  @Getter('offerResponseReason', { namespace: 'livingAllocations' }) private offerResponseReason!: (responseCode: string|null, responseCategoryCode: number|null, reasonCode: number|null, offerResponses: any[], organCode: number) => string|undefined;

  type = '';
  records: any[] = [];

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {number} organ code
   */
  get organCode(): number {
    return Number(this.$route.params.organ_code) || 0;
  }

  /**
   * Gets configuration for the vue good table configuation
   *
   * @returns {TableConfig} Offer History Table configuration
   */
  get offerHistoryTableConfig(): TableConfig {
    return {
      data: this.offerHistoryRows || [],
      columns: [
        { label: 'Ranking', field: 'rank' },
        { label: this.$t('client_id').toString(), field: 'client_id', html: true },
        { label: 'Last Name', field: 'last_name' },
        { label: 'Program', field: 'program' },
        { label: (this.isNoOffer ? 'No Offer Category' : 'Response'), field: 'response' },
        { label: (this.isNoOffer ? 'No Offer Reason' : 'Reason'), field: 'reason' },
        { label: 'Date Time (EST)', field: 'date' },
      ],
      empty: 'There is no offer history',
    };
  }

  /**
   * Generate string representation of 'Reason' based on offer details
   *
   * @param {LivingAllocationOffer} offer the recipient offer from allocation entry
   * @returns {string} description of Reason
   */
  private describeOfferReason(offer?: LivingAllocationOffer): string {
    if (!offer) return '-';

    // TODO: need to show reason not reason category here
    let reasonValue = '';
    if (this.isNoOffer) {
      reasonValue = this.noOfferReasonCode(offer.no_offer_reason_category, offer.no_offer_reason_code) || '-';
    } else {
      reasonValue = this.offerResponseReason(offer.response_code, offer.response_reason_category_code, offer.response_reason_code, this.offerResponses, this.organCode) || '-';
    }
    return reasonValue;
  }

  /**
   * Generate string representation of 'Response' based on offer details
   *
   * @param {LivingAllocationOffer} offer the recipient offer from allocation entry
   * @returns {string} description of Response
   */
  private describeOfferResponse(offer?: LivingAllocationOffer): string {
    if (!offer) return '-';

    return (this.isNoOffer ? this.noOfferReasonCategory(offer.no_offer_reason_category) : this.offerResponseCode(offer.response_code)) || '-';
  }

  /**
   * Get all rows for table data
   *
   * @returns {any[]} Offer History Table configuration
   */
  get offerHistoryRows(): any[] {
    // if not an array OR there are no records
    if (!Array.isArray(this.records) || this.records.length <= 0) {
      return [];
    }
    const result: LivingAllocationRecipient[] = [];
    this.records.forEach((recipient: LivingAllocationRecipient) => {
      const row: any = {
        rank: recipient.rank,
        client_id: recipient.client_id,
        last_name: recipient.last_name,
        program: recipient.program || '-',
        response: this.describeOfferResponse(recipient.offer),
        reason: this.describeOfferReason(recipient.offer),
        date: this.getDateTimeOffered(recipient),
      };
      result.push(row);
    });
    return result || [];
  }

  public getDateTimeOffered(row: LivingAllocationRecipient): string|undefined {
    if (!row.offer) return undefined;

    return row.offer.datetime_offered ? this.parseDisplayDateTimeUiFromDateTime(row.offer.datetime_offered) : '-';
  }

  get isNoOffer(): boolean {
    return this.type === LivingAllocationOfferTypeValues.NoOffer;
  }

  get isBackup(): boolean {
    return this.type === LivingAllocationOfferTypeValues.Backup;
  }

  get getTitle(): string {
    switch(this.type) {
      case LivingAllocationOfferTypeValues.Primary:
        return 'Primary';
        break;
      case LivingAllocationOfferTypeValues.Backup:
        return 'Backup';
        break;
      case LivingAllocationOfferTypeValues.NoOffer:
        return 'No Offer';
        break;
      default:
        return '';
        break;
    }
  }

  public initializeModal(type: LivingAllocationOfferTypeValues): void {
    this.type = type;

    switch(this.type) {
      case LivingAllocationOfferTypeValues.Primary:
        this.records = this.primaryOffers;
        break;
      case LivingAllocationOfferTypeValues.Backup:
        this.records = this.backupOffers;
        break;
      case LivingAllocationOfferTypeValues.NoOffer:
        this.records = this.noOffers;
        break;
      default:
        this.records = [];
        break;
    }

    const targetModal = this.$refs.offerHistoryModal as ModalSection;
    targetModal.toggleModal();
  }

  // Toggle modal
  private closeModal(): void {
    (this.$refs.offerHistoryModal as ModalSection).hideModal();
  }
}
