import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { FeatureState, FeatureFlags, ObjectWithLocation, Configuration, FeatureAttributes } from '@/store/features/types';

export const getters: GetterTree<FeatureState, RootState> = {
  /**
   * Checks for bad configuration, if incorrectly setup return false
   * 
   * @returns boolean
   */
  sanityCheck(state, getters): boolean {
    let result = true;
    // check configuration, if fail, set return to false
    if (!getters.getFeatureAttributes.date_format) result = false;
    if (!getters.getConfiguration.base_class) result = false;
    if (!getters.getFeatureAttributes.default_locale) result = false;
    return result;
  },
  /**
   * check if the features have been loaded
   *
   * @returns {boolean} true only if features module state has 'features' information
   */
  isFeaturesLoaded(state: FeatureState): boolean {
    return !!state.features;
  },
  /**
   * returns allocation service features from api
   *
   * @returns {string[]}
   */
  getAllocationServiceFeatures(state: FeatureState): string[] {
    return state.features!.allocation_service || [];
  },
  /**
   * returns feature attributes from api
   *
   * @returns {FeatureAttributes}
   */
  getFeatureAttributes(state: FeatureState): FeatureAttributes {
    return state.features!.attributes || {};
  },
  /**
   * returns feature flags
   *
   * @returns {string[]}}
   */
  getFeatureFlags(state: FeatureState): string[] {
    return state.features!.flags || [];
  },
  /**
   * returns availale system modules
   *
   * @returns {string[]}
   */
  getSystemModules(state: FeatureState): string[] {
    return state.features!.system_modules || [];
  },
  /**
   * returns configuration attributes from api
   *
   * @returns {Configuration}
   */
  getConfiguration(state: FeatureState): Configuration {
    return state.configuration || {};
  },
  /**
   * returns true if CTR IPOS Heart enabled
   *
   * @returns {boolean}
   */
  ctrIposHeart(state, getters): boolean {
    return getters.getAllocationServiceFeatures.includes("ctr_ipos_heart");
  },
  /**
   * returns true if user select list enabled
   *
   * @returns {boolean}
   */
  showUserSelect(state, getters): boolean {
    return getters.getFeatureFlags.includes("enable_user_select");
  },
  /**
   * returns true if oneid enabled
   *
   * @returns {boolean}
   */
  oneid(state, getters): boolean {
    return getters.getFeatureFlags.includes("oneid");
  },
  /**
   * returns true if i18n enabled
   *
   * @returns {boolean}
   */
  i18nEnabled(state, getters): boolean {
    return getters.getFeatureFlags.includes("enable_i18n");
  },
  /**
   * returns default locale
   *
   * @returns {string|undefined}
   */
  getDefaultLocale(state, getters): string|undefined {
    return getters.getFeatureAttributes ? getters.getFeatureAttributes.default_locale : undefined;
  },
  /**
   * returns available locales
   *
   * @returns {string[]}
   */
  getAvailableLocales(state, getters): string[] {
    const enabled_locales = getters.getFeatureAttributes ? getters.getFeatureAttributes.enabled_locales : [];
    return getters.i18nEnabled ? enabled_locales : [getters.getDefaultLocale];
  },
  /**
   * returns date format
   *
   * @returns {string|undefined}
   */
  getDateFormat(state, getters): string|undefined {
    return getters.getFeatureAttributes && getters.getFeatureAttributes.date_format || undefined;
  },
  /**
   * returns help url
   *
   * @returns {string|undefined}
   */
  getHelpUrl(state: FeatureState, getters): string|undefined {
    return getters.getFeatureAttributes ? getters.getFeatureAttributes.help_url : undefined;
  },
  /**
   * returns application name prefix
   *
   * @returns {string}
   */
  applicationNamePrefix(state: FeatureState, getters): string {
    return getters.getFeatureAttributes ? getters.getFeatureAttributes.application_name_prefix : undefined;
  },
  /**
   * returns true if view_allocation_audit_messages enabled
   *
   * @returns {boolean}
   */
  viewAllocationAuditMessages(state: FeatureState, getters): boolean {
    return getters.getFeatureFlags.includes(FeatureFlags.view_allocation_audit_messages);
  },
  /**
   * returns array of regions
   *
   * @returns {string[]}
   */
  getRegions(state: FeatureState, getters): string[] {
    return getters.getConfiguration ? getters.getConfiguration.regions : [];
  },
  /**
   * returns logo as base64 string
   *
   * @returns {string}
   */
  getLogo(state: FeatureState, getters): string|null {
    return getters.getConfiguration ? getters.getConfiguration.logo : null;
  },
  /**
   * returns css base class
   *
   * @returns {string}
   */
  getConfigBaseClass(state: FeatureState, getters): string|undefined {
    return getters.getConfiguration ? getters.getConfiguration.base_class : undefined;
  },
  /**
   * returns true if region includes province code
   *
   * @returns {boolean}
   */
  regionIncludesProvinceCode(state: FeatureState, getters) {
    return (object: ObjectWithLocation): boolean => {
      const regions = getters.getRegions;
      const province_code = object.location?.province_code || null;
      return province_code ? regions.includes(province_code) : false;
    };
  },
  /**
   * returns true if fusion auth bypassed
   *
   * @returns {boolean}
   */
  bypassFusionAuth(state: FeatureState, getters): boolean {
    return getters.getFeatureFlags.includes(FeatureFlags.bypass_fusionauth);
  },
  /**
   * returns true if system module enabled
   *
   * @returns {boolean}
   */
  moduleEnabled(state: FeatureState, getters) {
    return (module: string): boolean => {
      return getters.getSystemModules.includes(module);
    };
  },
  /**
   * returns login provider name
   *
   * @returns {string}
   */
  getLoginProviderName(state: FeatureState, getters) {
    return getters.getConfiguration ? getters.getConfiguration.login_provider : null;
  }
};
