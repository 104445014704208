
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ObjectId } from "@/store/types";
import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { faLoveseat } from "@fortawesome/pro-solid-svg-icons";
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import TableCheckboxInput from '@/components/shared/TableCheckboxInput.vue';
import { Hospital, ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { DeceasedDonor, DeceasedDonorOrganRecovery, DonorConsentedOrganRecoveryForm, 
DeceasedDonorOrganDonations, PromiseResult, OrganDonationResponseType } from '@/store/deceasedDonors/types';

// Form schemas
export interface OrganDonationsForm {
  [key: string]: OrganRecoveryForm;
}

interface ModalContentState {
  style?: string;
  body?: string;
}

interface OrganRecoveryForm {
  _id?: ObjectId;
  recovered?: boolean;
  exVivoUsed?: boolean;
  vesselsRecoveredForTransplant?: boolean;
  transplantDestinationIdentifier?: string;
  transplantDestination?: string|null;
  oopTransplantDestination?: string;
  recoveredResearchDestination?: string;
  odsShippedDate?: string|null;
  odsShippedTime?: string|null;
  odsShippingMethod?: number;
  odsShippingMethodOther?: string;
  odsDeliveredDate?: string|null;
  odsDeliveredTime?: string|null;
  odsReceivedBy?: string;
  exportOop?: boolean;
}

@Component({
  components: {
    SubSection,
    CardSection,
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    ModalSection,
    CheckboxInput,
    SelectOtherInput,
    TableCheckboxInput,
  }
})
// TODO: still waiting on data for this components so it will need to be reworked a bit soon
export default class OrganRecoveryData extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.hospitals.all) private hospitals!: Hospital[];
  @State(state => state.lookups.organ_delivery_status) OrganDeliveryStatusLookup!: GenericCodeValue[];
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.organRecovery) editState!: { [key: string]: OrganDonationsForm };
  @State(state => state.pageState.currentPage) modalState!: { [key: string]: OrganDonationsForm };

  @Getter('province', { namespace: 'lookups' }) private provinceLookup: any;
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('organRecoveryRowData', { namespace: 'deceasedDonors' }) private organRecoveryRowData: any;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) ontarioTransplantOptions!: any[];
  @Getter('getAllTransplantProgramsByOrgan', { namespace: 'hospitals' }) getAllTransplantProgramsByOrgan!: (organ_code: number[]) => any;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('getRegions', { namespace: 'features' }) private getRegions!: string[];

  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['country', 'organ_delivery_status'];

  // Build options list for Transplant Province
  get outOfProvinceTransplantOptions(): { code: string; value: string }[] {
    if (!this.provinceLookup) {
      return [];
    }
    // Exclude Ontario
    const filtered = this.provinceLookup.filter((option: { code: string; value: string }) => {
      return !this.getRegions.includes(option.code);
    });
    return filtered;
  }

  // get the id for the vessels for banking organ donation
  get vesselsForBankingId(): string|undefined {
    if(!this.deceasedDonor?.organ_donations) {
      return undefined;
    }
    const vessels = this.deceasedDonor.organ_donations!.find((element: DeceasedDonorOrganDonations) => {
      return element.organ_code == OrganCodeValue.VesselsForBanking;
    });
    return vessels?._id?.$oid;
  }

  /**
   * Get a select-input friendly list of ontario hospital transplant programs for the specified organ
   *
   * Create a list of filtered transplant hospitals containing the code and hospital name
   *
   * @returns {[{code: number, value: string}]} collection of transplant hospitals
   */
  public hospitalOptions(organ_code: number): { code: string; value: string }[] {
    return this.getAllTransplantProgramsByOrgan([organ_code]).map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  }


  // Clear Shipping Method other when Shipping Method changes
  public clearShippingMethodOther(organ_donation_id: string) {
    Vue.set(this.editState[organ_donation_id], "odsShippingMethodOther", null);
  }

  // public

  public buildOrganRecoveryForm(deceasedDonor?: DeceasedDonor): OrganDonationsForm {
    const organDonations = deceasedDonor?.organ_donations || [];
    const result: OrganDonationsForm = Object.assign({}, this.buildDonorOrganRecovery(organDonations));

    return result;
  }

  public buildDonorOrganRecovery(organDonations?: DeceasedDonorOrganDonations[]): OrganDonationsForm {
    const organRecoveryObject: OrganDonationsForm = {};

    this.donorConsentedOrganRecovery.forEach((organ: any, index: any) => {
      const organRecovery = organDonations!.find((organDonation: DeceasedDonorOrganDonations) => {
        return organDonation?._id?.$oid === organ.organ_donation_id;
      });


      if (organRecovery && organRecovery.organ_recovery) {
        const recoveredOrgan = organRecovery.organ_recovery[0];

        // test if exporting OOP (hsh heart or hsp kidney)
        const exportOop = this.isHeartOfferOop(organ.organ_donation_id) || this.isKidneyOfferOop(organ.organ_donation_id);

        organRecoveryObject[organ.organ_donation_id] = {
          recovered: recoveredOrgan.organ_recovered,
          exVivoUsed: recoveredOrgan.ex_vivo,
          vesselsRecoveredForTransplant: recoveredOrgan.vessels_recovered,
          transplantDestinationIdentifier: recoveredOrgan.destination_program_identifier,
          oopTransplantDestination: recoveredOrgan.destination_oop,
          recoveredResearchDestination: recoveredOrgan.destination_research ? recoveredOrgan.destination_research.$oid : undefined,
          odsShippedDate: recoveredOrgan.shipped_date ? this.parseDateUiFromDateTime(recoveredOrgan.shipped_date) : null,
          odsShippedTime: recoveredOrgan.shipped_date ? this.parseTimeUiFromDateTime(recoveredOrgan.shipped_date) : null,
          odsShippingMethod: recoveredOrgan.shipping_method_code,
          odsShippingMethodOther: recoveredOrgan.shipping_method_other,
          odsDeliveredDate: recoveredOrgan.delivered_date ? this.parseDateUiFromDateTime(recoveredOrgan.delivered_date) : null,
          odsDeliveredTime: recoveredOrgan.delivered_date ? this.parseTimeUiFromDateTime(recoveredOrgan.delivered_date) : null,
          odsReceivedBy: recoveredOrgan.received_by,
          // We look for this value specifically when attempting to ship, all non OOP rows should not have a value here
          exportOop: exportOop ? organRecovery.exported_oop : undefined
        };
      } else {
        organRecoveryObject[organ.organ_donation_id] = {
          recovered: false,
          exVivoUsed: false,
          vesselsRecoveredForTransplant: false,
          // TODO: transplantDestination: recoveredOrgan.,
          oopTransplantDestination: '',
          recoveredResearchDestination: undefined,
          odsShippedDate: undefined,
          odsShippedTime: undefined,
          // TODO: odsShippingMethod: recoveredOrgan.,
          odsDeliveredDate: undefined,
          odsDeliveredTime: undefined,
          odsReceivedBy: '',
          exportOop: false,
        };
      }
    });

    // get vessels to build form
    let vessels;
    if (this.deceasedDonor?.organ_donations) {
      vessels = this.deceasedDonor.organ_donations!.find((element: DeceasedDonorOrganDonations) => {
        return element.organ_code == OrganCodeValue.VesselsForBanking;
      });
    }

    // build vessels for banking form
    if (vessels && vessels.organ_recovery && vessels._id?.$oid) {
      const vesselsRecovery = vessels.organ_recovery[0];
      organRecoveryObject[vessels?._id?.$oid] = {
        recovered: vesselsRecovery.organ_recovered,
        transplantDestination: vesselsRecovery.destination_program ? vesselsRecovery.destination_program.$oid : undefined,
        odsShippedDate: vesselsRecovery.shipped_date ? this.parseDateUiFromDateTime(vesselsRecovery.shipped_date) : null,
        odsShippedTime: vesselsRecovery.shipped_date ? this.parseTimeUiFromDateTime(vesselsRecovery.shipped_date) : null,
        odsDeliveredDate: vesselsRecovery.delivered_date ? this.parseDateUiFromDateTime(vesselsRecovery.delivered_date) : null,
        odsDeliveredTime: vesselsRecovery.delivered_date ? this.parseTimeUiFromDateTime(vesselsRecovery.delivered_date) : null,
      };
    }

    return organRecoveryObject;
  }

  /**
   * Get a list of organs that are consented to build the organ recovery table
   *
   * Create a filtered list of consented organs
   *
   * @returns {DonorConsentedOrganRecoveryForm[]} collection of consented organs
   */
  get donorConsentedOrganRecovery(): DonorConsentedOrganRecoveryForm[] {
    // Just in case we don't have the organ_donations object ready yet
    if (!this.deceasedDonor?.organ_donations) return [];

    const donatedOrgans = this.deceasedDonor.organ_donations;
    if (!donatedOrgans) return [];

    // Filter out Vessels for Banking, because it has its own separate table
    const filtered: DeceasedDonorOrganDonations[] = donatedOrgans.filter((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation.organ_code != OrganCodeValue.VesselsForBanking;
    });

    // Sort organ donations, so that organ rows for the same organ are grouped together in a certain order
    const sorted = filtered.sort((a: DeceasedDonorOrganDonations, b: DeceasedDonorOrganDonations) => {
      // Primary sort by numeric Organ Code
      let result = Math.sign((a.organ_code || 0) - (b.organ_code || 0));
      if (result != 0) return result;

      // Secondary sort by numeric Organ Specification
      result = Math.sign((a.organ_specification_code || 0) - (b.organ_specification_code || 0));
      if (result != 0) return result;

      // Tertiary sort by Created At date
      const aCreatedAtTimestamp = !!a.created_at ? new Date(a.created_at).getTime() : 0;
      const bCreatedAtTimestamp = !!b.created_at ? new Date(b.created_at).getTime() : 0;
      result = Math.sign(aCreatedAtTimestamp - bCreatedAtTimestamp);
      return result;
    });

    // Map organ donations to the recovery table edit state format
    const organs: DonorConsentedOrganRecoveryForm[] = sorted.map((organDonation: DeceasedDonorOrganDonations) => {
      const organName = this.getOrganSpecificationName(organDonation.organ_code, organDonation.organ_specification_code);
      return {
        label: this.$t(organName).toString(),
        organ_code: organDonation.organ_code || 0,
        organ_specification_code: organDonation.organ_specification_code || null,
        organ_donation_id: organDonation?._id?.$oid || '',
      };
    });
    return organs;
  }

  /**
   * Return true if the row contains a Heart intended for OOP export
   *
   * @returns {boolean} true if offer is for an OOP Heart
   */
  private isHeartOfferOop(organDonationId: string): boolean {
    // Find the organ_donation item matching the row
    const organDonation = (this.deceasedDonor?.organ_donations || []).find((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation?._id?.$oid === organDonationId;
    });
    // If none return false
    if (!organDonation) return false;
    // If this is a Heart and oop_recipient
    if (organDonation.organ_code === OrganCodeValue.Heart && organDonation.organ_offer?.oop_recipient) return true;
    return false;
  }

  /**
   * Return true if the row contains a Kindey intended for OOP export
   *
   * @returns {boolean} true if offer is for an OOP Kidney
   */
  private isKidneyOfferOop(organDonationId: string): boolean {
    // Find the organ_donation item matching the row
    const organDonation = (this.deceasedDonor?.organ_donations || []).find((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation?._id?.$oid === organDonationId;
    });
    // If none return false
    if (!organDonation) return false;
    // If this is a Kidney and oop_recipient
    if (organDonation.organ_code === OrganCodeValue.Kidney && organDonation.organ_offer?.oop_recipient) return true;
    return false;
  }

  // Event handlers
  private mounted(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      this.initializeForm();
    });
    this.$emit('loaded', 'donorDataHistory');
  }

  // Public methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organRecovery',
      value: this.buildOrganRecoveryForm(this.deceasedDonor)
    });
  }


  /**
  * Builds an array of Organ Donations using the OrganRecoveryForm editstate
  *
  * @param organRecoveryObject the OrganRecoveryForm object
  */
  public organRecoveryObjectToArray(organRecoveryObject: { [key: string]: OrganDonationsForm }): DeceasedDonorOrganDonations[] {
    const organRecoveryArray: DeceasedDonorOrganDonations[] = [];
    this.donorConsentedOrganRecovery.forEach((organ: DonorConsentedOrganRecoveryForm, index: number) => {
      const objectElement = organRecoveryObject[organ.organ_donation_id];

      const organ_donation = {
        _id: { $oid: organ.organ_donation_id },
        organ_code: organ.organ_code,
        organ_specification_code: organ.organ_specification_code,
        organ_recovery: [this.organRecoveryFormToOrganDonation(objectElement)]
      };
      organRecoveryArray.push(organ_donation);
    });

    let vessels_for_banking = {};

    // build vessels for banking object from editState (organRecoveryObject)
    if (this.vesselsForBankingId) {
      const vessels_recovery = organRecoveryObject[this.vesselsForBankingId] as OrganRecoveryForm;

      vessels_for_banking = {
        _id: { $oid: this.vesselsForBankingId },
        organ_code: OrganCodeValue.VesselsForBanking,
        organ_recovery: [{
          organ_recovered: vessels_recovery.recovered,
          destination_program: vessels_recovery.transplantDestination ? { $oid: vessels_recovery.transplantDestination } : null,
          shipped_date: vessels_recovery.odsShippedDate ? this.sanitizeDateTimeApi(vessels_recovery.odsShippedDate, vessels_recovery.odsShippedTime || undefined) : null,
          delivered_date: vessels_recovery.odsDeliveredDate ? this.sanitizeDateTimeApi(vessels_recovery.odsDeliveredDate, vessels_recovery.odsDeliveredTime || undefined) : null,
        }]
      };
    }
    // push vessels for banking to array for saving
    organRecoveryArray.push(vessels_for_banking);

    return organRecoveryArray;
  }

  public organRecoveryFormToOrganDonation(recoveryForm: OrganRecoveryForm): DeceasedDonorOrganRecovery {
    return {
      organ_recovered: recoveryForm.recovered,
      ex_vivo: recoveryForm.exVivoUsed,
      vessels_recovered: recoveryForm.vesselsRecoveredForTransplant,
      destination_oop: recoveryForm.oopTransplantDestination,
      destination_research: ( recoveryForm.recoveredResearchDestination ? { $oid: recoveryForm.recoveredResearchDestination } : null),
      shipped_date: recoveryForm.odsShippedDate ? this.sanitizeDateTimeApi(recoveryForm.odsShippedDate, recoveryForm.odsShippedTime || undefined) : null,
      shipping_method_code: recoveryForm.odsShippingMethod,
      shipping_method_other: recoveryForm.odsShippingMethodOther,
      delivered_date: recoveryForm.odsDeliveredDate ? this.sanitizeDateTimeApi(recoveryForm.odsDeliveredDate, recoveryForm.odsDeliveredTime || undefined) : null,
      received_by: recoveryForm.odsReceivedBy
    };
  }

  public extractOrganRecoveryForm(organDonation?: { [key: string]: OrganDonationsForm }): DeceasedDonor {
    const organRecoveryArray = organDonation ? this.organRecoveryObjectToArray(organDonation) : [];
    const result: DeceasedDonor = {
      organ_donations: organRecoveryArray
    };
    return result;
  }


   /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Heart Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveOrganRecoveryData as unknown as SaveProvider;
    // Report to parent that saving has began - this should the card section ref
    this.$emit('save', 'saveOrganRecoveryData');
    // Generate payload based on current edit state
    const donorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch }).then((success: SaveResult) => {

      // build shipping list (collection of promises to ship organs)
      const shippingList = this.buildShippingList();
      // process list
      Promise.allSettled(shippingList).then((results: PromiseSettledResult<SaveResult>[]) => {
        // if something was shipped, handle the outcome
        if (shippingList.length > 0) {
          // have to cast promise array as typescript does not know what type it is
          const typedResults = results as PromiseResult[];
          const fulfilled = typedResults.filter((item: PromiseResult) => { return item.value?.status === 200;} );
          const rejected = typedResults.filter((item: PromiseResult) => { return item.value?.status !== 200;} );
          // // as long as organs have been shipped, check and show outcome modal
          if (rejected.length > 0) {
            // if rejected, show this
            this.showOutcomeModalForShippedOrgan(rejected[0]);
          } else {
            // otherwise show fulfilled popup
            this.showOutcomeModalForShippedOrgan(fulfilled[0]);
          }
        }

        // refresh results and return
        this.$store.dispatch('deceasedDonors/get', this.clientId).then(() => {
          this.$store.dispatch('deceasedDonors/loadOrganDonations', this.clientId).then(() => {
            this.$store.dispatch('deceasedDonors/loadExdConfirmations', this.clientId);
              saveProvider.registerSaveResult(success);
              this.$emit('clear');
              this.initializeForm();
              return results;
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  private showOutcomeModalForShippedOrgan(result: PromiseResult): void {
    const shipResult: OrganDonationResponseType|null = result && result.value ? result.value : null;
    if (shipResult && shipResult.data) {
      // Check the return value of organ_donation(s) to determine if the organ(s) were shipped or not
      const exportedOopResult = !!shipResult.data?.organ_donation?.exported_oop;
      // If we have an organ_donation object in the response then we shipped
      this.displayOutcomeModal(!!shipResult.data?.organ_donation, exportedOopResult);
      // Fetch our donor again so we can show the new exportOop value
    }
  }

  // Attempt to ship/unship our organ if we have any exportOop flag set
  public buildShippingList(): Promise<SaveResult>[] {
    // Array of ids with the exportOop value needed to ship
    const exportOopRows: { [key: string]: boolean }[] = [];

    // Only add items that have an exportOop value (there should only ever be one here)
    for (const [key, value] of Object.entries(this.editState)) {
      if (value.exportOop != undefined) exportOopRows.push({ [key]: !!value.exportOop });
    }
    // Array of shippable organs
    const organsToShip: Promise<SaveResult>[] = [];
    // If we have any exportOopRows
    if ((exportOopRows || []).length > 0) {
      // Check each row for a shipped organ, in case multiple organs are shipped at once
      for (const exportOopRow of exportOopRows) {
        // First key is the organDonationId, First value is the exportOop value
        const [organDonationId, exportOop] = [Object.keys(exportOopRow)[0], Object.values(exportOopRow)[0]];
        // Find the existing value for exported_oop
        const existingExportOopValue = this.deceasedDonor.organ_donations?.find((organDonation: DeceasedDonorOrganDonations) => {
          return organDonation._id?.$oid === organDonationId;
        });
        // Check if we have a change in value
        const exportOopChanged = existingExportOopValue?.exported_oop != exportOop;
        // If changed, add to promise array of organs to ship
        if (exportOopChanged) {
          organsToShip.push(this.$store.dispatch('deceasedDonors/shipOrgan', { donorId: this.clientId, organDonationId: organDonationId, ship: exportOop }));
        }
      }
    }
    return organsToShip;
  }

  // Display a success notification after a successful export to CTR action
  private displayOutcomeModal(success: boolean, ship: boolean): void {
    // Initialize our modal state
    Vue.set(this.modalState, 'organShipModal', { style: '', body: '' });
    // Change the content based on the success
    const modalContent = {
      style: `modal-${ success ? 'success' : 'danger' }`,
      body: success ? this.$t(`export_to_ctr_success_${ship ? 'ship' : 'unship'}`) : this.$t('export_to_ctr_fail'),
    };
    Vue.set(this.modalState, 'organShipModal', modalContent);
    // Show our modal if the ref exists
    if (this.$refs.exportOutcomeModal) (this.$refs.exportOutcomeModal as ModalSection).showModal();
  }

  // Toggle the modal
  private toggleModal(): void {
    // Clear our modal state
    Vue.set(this.modalState, 'organShipModal', { style: '', body: '' });
    // Close our modal
    (this.$refs.exportOutcomeModal as ModalSection).toggleModal();
  }

  /**
   * Gets changes from the editState as a patch for the journey's Heart Specific Details
   *
   * If the edit state doesn't exist return null
   *
   * @returns {DeceasedDonor} object containing field changes
   */
  public extractPatch(): DeceasedDonor {
    if (!this.editState) {
      return {};
    } else {
      return this.extractOrganRecoveryForm(this.editState);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const organRecoveryData = this.$refs.saveOrganRecoveryData as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    organRecoveryData.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result: { [key: string]: string } = {};
    const organDonations = this.deceasedDonor?.organ_donations || [];
    organDonations.forEach((or: DeceasedDonorOrganDonations, index: number) => {
      const organDonationId = or?._id?.$oid;
      if (organDonationId) {
        result[`organ_donations[${organDonationId}].organ_recovery[0].organ_recovered`]       = `or-recovered-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].ex_vivo`]               = `or-ex-vivo-used-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].vessels_recovered`]     = `or-vessels-recovered-transplant-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].destination_oop`]       = `or-transplant-destination-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].destination_research`]  = `or-research-destination-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipped_date`]          = `or-shipped-date-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipped_time`]          = `or-shipped-time-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.shipped_date`]                      = `or-shipped-date-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.shipped_date`]                      = `or-shipped-time-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipping_method_code`]  = `or-shipping-method-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipping_method_other`] = `or-shipping-method-other-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].delivered_date`]        = `or-delivered-date-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].delivered_time`]        = `or-delivered-time-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].received_by`]           = `or-received-by-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.delivered_date`]                    = `or-delivered-date-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.delivered_date`]                    = `or-delivered-time-${organDonationId}`;
      }
    });
    return result;
  }

}
