
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';

import { TableConfig } from '@/types';
import { ObjectId } from '@/store/types';
import { BmiResult } from '@/store/tools/types';
import { GenericCodeValue } from '@/store/types';
import { LivingDonor, LivingDonorMeasurement } from '@/store/livingDonors/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { BloodType, SubBloodType, RhIndicator, BloodTypeValue, RhIndicatorValue, ExceptionalDistributionCodeValues } from '@/store/lookups/types';

import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';

export interface GeneralClinicalForm {
  bloodType?: string|null;
  aboSubType?: string|null;
  rhIndicator?: string|null;
  bloodTypeVerified?: boolean|null;
  exceptionalDistribution?: boolean;
  exdReasonCodes?: number[];
  exdReasonDetailsOther?: string|undefined;
  exdReasonDetailsTravel?: string;
  exdReasonDetailsTransmission?: string;
  measurement: MeasurementForm;
}

interface MeasurementRow {
  _id?: ObjectId;
  measurementDate?: string;
  weightKg?: string;
  heightCm?: string;
  bmi?: string;
}

interface MeasurementForm {
  _id?: ObjectId;
  date?: string;
  weight?: number|null;
  height?: number|null;
  weight_lbs?: number|null;
  height_in?: number|null;
  bmi?: number;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SelectInput,
    SaveToolbar,
    NumberInput,
    CheckboxInput,
    HiddenInput
  }
})
export default class GeneralClinicalInformation extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.tools.bmiResult) bmiResult!: BmiResult;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.pageState.currentPage.generalClinicalLivingDonor) editState!: GeneralClinicalForm;
  @State(state => state.lookups.donor_exceptional_distribution) exceptionalDistributionLookup!: GenericCodeValue[];

  // Getters
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('bloodTypesForLivingDonor', { namespace: 'lookups' }) private bloodTypesForLivingDonor!: BloodType[];
  @Getter('rhIndicatorsForLivingDonor', { namespace: 'lookups' }) private rhIndicatorsForLivingDonor!: RhIndicator[];
  @Getter('exceptionalDistributionOptionsForLivingDonor', { namespace: 'lookups' }) private exceptionalDistributionOptionsForLivingDonor!: GenericCodeValue[];
  @Getter('bloodSubTypes', { namespace: 'lookups' }) bloodSubTypes!: (bloodType: string) => SubBloodType[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('hasSelectedLivingDonorConsentedOrgans', { namespace: 'livingDonors' }) private hasSelectedLivingDonorConsentedOrgans!: boolean;

  // Properties
  @Prop({ default: false }) canSave!: boolean;
  @Prop({ default: false }) newLivingDonor!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['blood_type', 'sub_blood_type', 'rh_indicator', 'country', 'donor_exceptional_distribution'];

  // Instance attributes
  private isFinishedLoadingLookups = false;

  /**
   * Gets boolean representation if we have A or AB blood type selected
   *
   * @returns {boolean} true if A or AB selected
   */
  get showAboSubType(): boolean {
    if (!this.editState || !this.editState.bloodType) {
      return false;
    }
    return this.editState.bloodType == BloodTypeValue.A || this.editState.bloodType == BloodTypeValue.AB;
  }

  // Gets a filtered list of sub blood type values by passing the blood type
  get bloodSubTypeOptions(): SubBloodType[] {
    if (this.editState.bloodType == null) {
      return [];
    }
    return this.bloodSubTypes(this.editState.bloodType);
  }

  // Prepare recipient measurements to be display in the historical table
  get measurementRows(): MeasurementRow[] {
    // TODO: Check if living donor is getting measurements
    if (!this.editState || !this.livingDonor.measurements) {
      return [];
    }
    // Sort source documents
    const sorted = this.livingDonor.measurements.sort((a: LivingDonorMeasurement, b: LivingDonorMeasurement): number => {
      // Generate date objects, assuming today's date if the parameter is missing
      const aDate = a.date ? new Date(a.date) : new Date();
      const bDate = b.date ? new Date(b.date) : new Date();
      // Sort using the difference between the numeric timestamps from each date
      return bDate.getTime() - aDate.getTime();
    });
    const rows = sorted.map((measurement: LivingDonorMeasurement) => {
      // Masking hides these value so convert to integers before attempting toFixed()
      const result = {
        _id: measurement._id,
        measurementDate: this.parseDisplayDateUi(measurement.date) || '-',
        weightKg: measurement.weight == null ? '-' : this.toFixed(measurement.weight, 1),
        heightCm: measurement.height == null ? '-' : this.toFixed(measurement.height, 1),
        bmi: measurement.bmi == null ? '-' : this.toFixed(measurement.bmi, 1),
      };
      return result;
    });
    return rows;
  }

  /**
   * Return true if we can save measurement
   *
   * @returns {boolean} true if we have permission
   */
  get canSaveMeasurement(): boolean {
    return this.isGroupWriteable('living_donor_personal');
  }

    /**
   * Return true if we can edit the selected measurement
   *
   * Note: this check selected measurement if there is one,
   * because historical entries are read-only. If nothing
   * is selected, we return true since 'new' can be saved.
   *
   * @returns {boolean} true if we can edit
   */
  get isNewOrMostRecentEntry(): boolean {
    // no state and no recipient measurements
    if (!this.editState && !this.livingDonor.measurements) {
      return true;
    }
    // ensure we have a measurement selected
    if (!!this.editState?.measurement) {
      const measurementId = this.editState.measurement._id;
      const livingDonorMeasurements = this.livingDonor.measurements;
      if (!!measurementId && !!livingDonorMeasurements) {
        return this.isLastEntry(measurementId, livingDonorMeasurements);
      }
    }
    return true;
  }

  public mounted(): void {
    this.initializeForm();
  }

  public loadMeasurements() {
    this.$store.dispatch('livingDonors/loadMeasurements', this.livingDonorId);
  }

  // Copy relevant data from livingDonor into the pageState
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinicalLivingDonor',
      value: this.buildGeneralClinicalForm(this.livingDonor)
    });
  }

  // Extract relevant parts from Recipient to match the form layout
  public buildGeneralClinicalForm(livingDonor: LivingDonor): GeneralClinicalForm {
    if (!livingDonor) {
      return {
        exdReasonCodes: [],
        measurement: {}
      };
    }

    const livingDonorBlood = livingDonor.blood || {};
    const livingDonorIndicators = livingDonor.indicators || {};
    const exdReasonCodes = (livingDonorIndicators.exd_reason_codes && livingDonorIndicators.exd_reason_codes.length > 0) ? livingDonorIndicators.exd_reason_codes : [];

    const result = {
      bloodType: livingDonorBlood.type,
      aboSubType: livingDonorBlood.sub_type,
      rhIndicator: livingDonorBlood.rh,
      bloodTypeVerified: livingDonorBlood.type_verified,
      exceptionalDistribution: livingDonorIndicators.exceptional_distribution || false,
      exdReasonCodes: exdReasonCodes,
      exdReasonDetailsOther: livingDonorIndicators.exd_reason_details_other || undefined,
      exdReasonDetailsTravel: livingDonorIndicators.exd_reason_details_travel || undefined,
      exdReasonDetailsTransmission: livingDonorIndicators.exd_reason_details_transmission || undefined,
      measurement: {
        // TODO: add in measurements
      }
    };

    return result;
  }

  // Extract patch used for saving just the ExD section (indicators)
  public extractExDPatch(): LivingDonor {
    let exdReasonOther: string|undefined = undefined;
    let exdReasonOtherTravel: string|undefined = undefined;
    let exdReasonOtherTransmission: string|undefined = undefined;

    const filteredCodes = this.filterNullValues(this.editState.exdReasonCodes);
    const exdReasonCodes = filteredCodes.length > 0 ? filteredCodes : null;

    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.Other)) {
      exdReasonOther = this.editState.exdReasonDetailsOther;
    }
    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.OtherTravel)) {
      exdReasonOtherTravel = this.editState.exdReasonDetailsTravel;
    }
    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.OtherIncreasedRisk)) {
      exdReasonOtherTransmission = this.editState.exdReasonDetailsTransmission;
    }

    return {
      indicators: {
        exceptional_distribution: this.editState.exceptionalDistribution,
        exd_reason_codes: this.editState.exdReasonCodes,
        exd_reason_details_other: exdReasonOther || null,
        exd_reason_details_travel: exdReasonOtherTravel || null,
        exd_reason_details_transmission: exdReasonOtherTransmission || null,
      }
    };
  }

  // Extract patch used for saving just the Clinical section (blood)
  public extractClinicalPatch(): LivingDonor {
    const aboSubType = this.showAboSubType ? this.editState.aboSubType : null;

    return {
      blood: {
        type: this.editState.bloodType,
        sub_type: aboSubType,
        type_verified: this.editState.bloodTypeVerified,
        rh: this.editState.rhIndicator,
      },
    };
  }

  // Extract patch used for saving the entire card section (except measurements)
  public extractPatch(): LivingDonor {
    return {
      ...this.extractClinicalPatch(),
      ...this.extractExDPatch()
    };
  }

  /**
   * Clear exd reason codes on changing exceptional distribution
   *
   * @returns {void}
   */
  public changeEXDStatus(): void {
    Vue.set(this.editState, 'exdReasonCodes', []);
  }

  // Handle saving (blood) triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveGeneralClinical as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'generalClinical');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractClinicalPatch();
    const livingDonorId = this.livingDonorId;

    // Dispatch save action and register the response
    this.$store.dispatch('livingDonors/saveLivingDonor', { livingDonorId, livingDonor: livingDonorPatch }).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('livingDonors/set', success.responseData.living_donor);
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Handle saving (indicators) triggered by local save button
  public saveExDPatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveExD as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'saveExD');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractExDPatch();
    const livingDonorId = this.livingDonorId;

    console.log(livingDonorPatch);

    // Dispatch save action and register the response
    this.$store.dispatch('livingDonors/saveLivingDonor', { livingDonorId, livingDonor: livingDonorPatch }).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('livingDonors/set', success.responseData.living_donor);
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    // Note: We are not resetting measurements here because the parent saving does not save measurements.
    const gci = this.$refs.saveMeasurement as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  get idLookup(): {[key: string]: string} {
    const result = {
      'blood.type'                                 : 'ld-clinical-blood-type',
      'blood.sub_type'                             : 'ld-clinical-abo_sub_type',
      'blood.rh'                                   : 'ld-clinical-rh',
      'blood.type_verified'                        : 'ld-clinical-btv',
      'indicators.exceptional_distribution'        : 'exceptional_distribution',
      'indicators.exd_reason_codes'                : 'exd_reason_codes',
      'indicators.exd_reason_codes.3'              : 'exd_reason_codes',
      'indicators.exd_reason_codes.4'              : 'exd_reason_codes',
      'indicators.exd_reason_details_other'        : 'exd_reason_details_other',
      'indicators.exd_reason_details_travel'       : 'exd_reason_details_travel',
      'indicators.exd_reason_details_transmission' : 'exd_reason_details_transmission',
      'measurements.date'                          : 'date',
      'measurements.height'                        : 'height',
      'measurements.weight'                        : 'weight',
    };
    return result;
  }

  // Bubble up validation errors
  public handleErrors(errorResult: SaveResult): void {
    this.$emit('handleErrors', errorResult);
  }

  /**
   * TODO: TECH_DEBT: this should be split into two peices of logic:
   * - boolean getter for whether or not the Verified checkbox is disabled
   * - event handlers on the Type and RH dropdowns to change edit state
   *
   * I.e. disabled prop in template should be based on getter, but getters
   * should not be mutating the form edit state
   */
  //Disable Blood Type Verified if Blood Type or Rh Factor is Unknown
  public isBloodTypeVerifiedDisabled(): boolean {
    const isVerified = this.editState.bloodType === BloodTypeValue.Unknown
      || this.editState.rhIndicator === RhIndicatorValue.Unknown;

    if (isVerified) {
      Vue.set(this.editState, 'bloodTypeVerified', false);
      return true;
    }
    return false;
  }

  // Updates BMI, Height and Weight from API triggered on change
  // from height / weight fields, or selecting a measurement
  public calculateMeasurement(from?: string) {
    let params = {};
    if (from === 'in') {
      params = {
        height: this.editState.measurement!.height_in,
        height_unit: 'in',
        weight: this.editState.measurement!.weight,
        weight_unit: 'kg',
      };
    } else if (from === 'lbs') {
      params = {
        height: this.editState.measurement!.height,
        height_unit: 'cm',
        weight: this.editState.measurement!.weight_lbs,
        weight_unit: 'lbs',
      };
    } else {
      params = {
        height: this.editState.measurement!.height,
        height_unit: 'cm',
        weight: this.editState.measurement!.weight,
        weight_unit: 'kg',
      };
    }
    this.$store.dispatch('tools/loadBmiHeightWeight', params).then(() => {
      // update pageState with new values
      this.setMeasurement(this.editState.measurement, this.bmiResult);
    }).catch((error) => {
      console.warn(error.description);
    });
  }

  // Config options for the measurements table
  public measurementsTableConfig(): TableConfig {
    return {
      data: this.measurementRows,
      columns: [
        { label: this.$t('measurement_date').toString(), field: 'measurementDate', width: '25%' },
        { label: this.$t('weight_kg').toString(), field: 'weightKg', width: '25%' },
        { label: this.$t('height_cm').toString(), field: 'heightCm', width: '25%' },
        { label: this.$t('bmi').toString(), field: 'bmi', width: '25%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSaveMeasurement,
      createText: this.$t('create_measurement').toString()
    };
  }

  // Sanitize then save selected measurement into pageState
  public setMeasurement(existingMeasurement: MeasurementForm, bmiResponse: BmiResult) {
    const measurementChanges: MeasurementForm = {};
    // Set calculated BMI if it was provided in the calculation response
    if (bmiResponse.bmi !== undefined && bmiResponse.bmi !== null ) {
      measurementChanges.bmi = bmiResponse.bmi;
    }
    // Convert height if applicable
    if (bmiResponse.height_cm >= 0 && bmiResponse.height_in >= 0) {
      measurementChanges.height = parseFloat(this.toFixed(bmiResponse.height_cm, 1));
      measurementChanges.height_in = parseFloat(this.toFixed(bmiResponse.height_in, 1));
    }
    // Convert weight if applicable
    if (bmiResponse.weight_kg >= 0 && bmiResponse.weight_lbs >= 0) {
      measurementChanges.weight = parseFloat(this.toFixed(bmiResponse.weight_kg, 1));
      measurementChanges.weight_lbs = parseFloat(this.toFixed(bmiResponse.weight_lbs, 1));
    }
    // Merge existing measurement data with calculated data
    const sanitizeMeasurement = {
      _id: existingMeasurement._id,
      date: existingMeasurement.date,
      weight: measurementChanges.weight || existingMeasurement.weight,
      height: measurementChanges.height || existingMeasurement.height,
      weight_lbs: measurementChanges.weight_lbs || existingMeasurement.weight_lbs,
      height_in: measurementChanges.height_in || existingMeasurement.height_in,
      bmi: measurementChanges.bmi || existingMeasurement.bmi,
    };
    // Update form state
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinicalLivingDonor',
      componentKey: 'measurement',
      value: sanitizeMeasurement
    });
  }

  /**
   * Saves the Measurement section (selected or new item)
   *
   * Extract the measurement section from editState and package up a payload to send.  If the measurement already exists
   * the dispatch will have an id and use that to PATCH a measurement.  If the measurement is new, we then make a POST
   * request and create a new measurement.  After success we load the measurements and update the page.  The dispatch
   * returns a SaveResult promise with the Recipient record, or an error object with an array or errors the were returned.
   */
  public saveMeasurement() {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveMeasurement as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'measurement');
    // Generate payload based on current edit state
    const measurementPayload = {
      id: this.editState.measurement!._id,
      livingDonorId: this.livingDonorId,
      measurement: this.extractMeasurementPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('livingDonors/saveMeasurement', measurementPayload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's measurements and show success notification
      this.loadMeasurements();
      saveProvider.registerSaveResult(success);
      // Clear form for entry of a new measurement
      this.initializeForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Load selected measurement into pageState
  public selectMeasurement(event: any) {
    // Get Measurement ID from the table row referenced in the select event
    const selectedMeasurementId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    // Retrieve the Measurement object from all the Measurements
    const foundMeasurement: LivingDonorMeasurement|undefined = this.livingDonor.measurements!
      .find((each: LivingDonorMeasurement) => {
        return each._id && each._id.$oid === selectedMeasurementId;
      });
    // If we found a Measurement
    if (foundMeasurement) {
      // Build form state
      const measurement = this.buildMeasurementForm(foundMeasurement);
      // // Save it to the editState
      this.$store.commit('pageState/set', {
        pageKey: 'generalClinicalLivingDonor',
        componentKey: 'measurement',
        value: measurement
      });
      // Update Height, Weight and BMI
      this.calculateMeasurement();
    }
  }

  // Modify the measurements to fit our format
  public buildMeasurementForm(measurement: LivingDonorMeasurement): MeasurementForm {
    const sanitizedMeasurement: MeasurementForm = {
      _id: measurement._id,
      date: this.parseDateUi(measurement.date),
      height: measurement.height,
      weight: measurement.weight,
      bmi: measurement.bmi
    };
    return sanitizedMeasurement;
  }

  // Returns a RecipientMeasurement patch request payload or null if the measurement edit state is empty
  public extractMeasurementPatch(): LivingDonorMeasurement|any {
    if (!this.editState || !this.editState.measurement || this.isMeasurementEmpty(this.editState.measurement)) {
      return {
        date: null,
        height: null,
        weight: null,
      };
    }
    return {
      _id: this.editState.measurement!._id,
      date: this.sanitizeDateApi(this.editState.measurement.date),
      weight: this.editState.measurement!.weight,
      height: this.editState.measurement!.height
    };
  }

  // Returns whether or not the measurement state is empty
  get isEmpty(): boolean {
    const measurement = this.editState.measurement;
    const isEmpty = (
      measurement.date == null &&
      measurement.height == null &&
      measurement.weight == null
    );
    console.log(isEmpty);
    return isEmpty;
  }

    // Set the measurement to an empty object
  public createMeasurement() {
    this.$store.commit('pageState/set', {
      pageKey: 'generalClinicalLivingDonor',
      componentKey: 'measurement',
      value: {}
    });

    this.$emit('clear');
    this.resetSaveToolbar();
  }

  private clear() {
    this.$emit('clear');
  }

  // PRIVATE

  // Returns whether or not the measurement edit state is empty
  private isMeasurementEmpty(measurement: MeasurementForm): boolean {
    if (!measurement) {
      return true;
    }
    return (measurement.date == null || measurement.date.length === 0)
        && (measurement.weight == null || measurement.weight.toString().length === 0)
        && (measurement.height == null || measurement.height.toString().length === 0);
  }

  // Returns a number to a fixed number of sidigits if it's a number
  private toFixed(number: any, sigdits: number): any {
    if (Number.isFinite(number)) {
      return number.toFixed(sigdits);
    } else {
      return number;
    }
  }

  private filterNullValues(values: any): any {
    return values.filter((el: any) => { return el != null; });
  }

  // Called when lookups finish loading
  private loaded(): void {
    this.isFinishedLoadingLookups = true;
    this.checkIfLoadingComplete();
  }

  // Section is finished loading when dependencies are loaded
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingLookups ) {
      this.initializeForm();
      this.$emit('loaded', 'generalClinical');
      if (!this.newLivingDonor) {
        this.loadMeasurements();
      }
    }
  }
}
