
import { SaveResult } from '@/types';
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { Hospital } from '@/store/hospitals/types';
import PageTop from '@/components/shared/PageTop.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { NumericCodeValue, GenericCodeValue } from '@/store/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { WaitlistQuery, WaitlistQueryResult } from '@/store/waitlist/types';
import { Organ, BloodType, OrganWaitlistMedicalStatusValue, OrganCodeValue } from '@/store/lookups/types';
import { User, Role } from '@/store/users/types';
import { format as dateFnsFormat, parse as dateFnsParse } from 'date-fns';
import { Format } from '@/store/utilities/types';
import { isMasked } from '@/utils';
import { DateUtilsMixin } from '@/mixins/date-utils-mixin';
import { mixins } from 'vue-class-component';
import LoadingReportView from '@/components/shared/LoadingReportView.vue';

interface WaitlistPageState {
  filters: WaitlistFilters;
  selectedColumns: string[];
}

interface WaitlistFilters {
  organ: string|null;
  donorType: string|null;
  cluster: boolean;
  medicalHold: boolean;
  serumHold: boolean;
  suspended: boolean;
  bloodTypes: string[];
  transplantPrograms: string[];
  // variables for tag inputs
  bloodTypeInput: string;
  bloodTypeTags: { text: string, tiClasses: string[] }[];
  transplantProgramInput: string;
  transplantProgramTags: { text: string, tiClasses: string[] }[];
}

interface WaitlistResultSummary {
  // Results Summary
  totalMatching?: number,
  totalClustered?: number
  totalMedicalHold?: number,
  totalSerumHold?: number,
  totalSuspended?: number,
}

const LIVING_DONOR_APPLICABLE_ORGAN_CODES = [
  OrganCodeValue.Kidney,
  OrganCodeValue.Liver,
  OrganCodeValue.Lung
];

@Component({
  components: {
    PageTop,
    TextInput,
    SubSection,
    SelectInput,
    SaveToolbar,
    ColumnConfig,
    CheckboxInput,
    LoadingReportView,
  }
})
export default class WaitlistSummary extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.vad_side) vadSideLookup!: GenericCodeValue[];
  @State(state => state.lookups.vad_indication) vadIndicationLookup!: NumericCodeValue[];
  @State(state => state.pageState.currentPage.waitlistReport) editState!: WaitlistPageState;
  @State(state => state.waitlist.waitlistQueryResult) waitlistQueryResult!: WaitlistQueryResult;
  @State(state => state.lookups.living_deceased_combinations) donorTypeLookup!: GenericCodeValue[];
  @State(state => state.users.user) user!: User;

  @Getter('getUserOrganCodes', { namespace: 'users' }) getUserOrganCodes!: number[];
  @Getter('getUserLaboratories', { namespace: 'users' }) getUserLaboratories!: Hospital[];
  @Getter('isCoordinator', { namespace: 'users' }) isCoordinator!: boolean;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;
  @Getter('medicalStatusPriority', { namespace: 'lookups' }) medicalStatusPriority!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null) => number;
  @Getter('getTransplantProgramsByOrgan', { namespace: 'hospitals' }) getTransplantProgramsByOrgan!: (organ_code?: number) => any;
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('getColumnPreferences', { namespace: 'users'}) getColumnPreferences!: (columnKey: string, optionDefaults: string[]) => string[];
  @Getter('regionIncludesProvinceCode', { namespace: 'features' }) regionIncludesProvinceCode!: (object: any) => boolean;
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];

  private lookupsToLoad: string[] = [
    'vad_side',
    'blood_type',
    'vad_indication',
  ];

  private printSummary(): void {
    window.print();
  }

  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
  formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  /**
   * Handles generating a CSV version of the content in the waitlist report table
   * as displayed on screen. Iterates over the currently displayed columns and generates as CSV string that
   * the browser is instructed to download.
   * @private
   */
  private downloadCSV(): void {
    const link = this.$refs.waitlistCsvReport;
    // This should never happen as the ref is what got us here
    // this is simply to appease typescript
    if(!link) {
      return;
    }
    if(!this.waitlistQueryResult) {
      return;
    }
    const recipients = this.waitlistGridRows;
    let csvContent = '';
    const headerRow: string[] = [];
    this.waitlistGridTableConfig.columns.forEach(col => {
      headerRow.push(col.label);
    });

    csvContent = headerRow.join(',') + "\n";

    recipients.forEach(r => {
      const rowContent: string[] = [];
      this.waitlistGridTableConfig.columns.forEach(col => {
        let d = r[col.field] ? r[col.field] : '';
        // We need special date handling logic here to mimic what VueGoodTable is doing
        if(col.dateInputFormat && d) {
          const parsedDate = dateFnsParse(d, col.dateInputFormat, new Date());
          const dateOutputFormat = col.dateOutputFormat || Format(this.getDateFormat).DISPLAY_DATE;
          d = dateFnsFormat(parsedDate, dateOutputFormat);
        }

        // Special logic to handle double quots as part of any content
        d = d.toString().replace(/"/g, '""');
        if (d.search(/("|,|\n)/g) >= 0) {
          d = '"' + d + '"';
        }
        rowContent.push(d);
      });

      csvContent += rowContent.join(',') + "\n";
    });

    csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    (link as Element).setAttribute('href', csvContent);
  }

  private bloodTypesIncluded = ["A", "B", "AB", "F", "O"];

  private isLoading = true;
  private isLookupsLoaded = false;
  private isHospitalsLoaded = false;

  private mounted(): void {
    Promise.all([
      this.$store.dispatch('lookups/load', { lookup: 'vad_side' }),
      this.$store.dispatch('lookups/load', { lookup: 'blood_type' }),
      this.$store.dispatch('lookups/load', { lookup: 'vad_indication' }),
    ]).finally(() => {
      this.isLookupsLoaded = true;
      this.loadHospitals();
      this.checkIfLoadingComplete();
    });
  }

  private loadHospitals(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.isHospitalsLoaded = true;
      this.checkIfLoadingComplete();
    });
  }

  private checkIfLoadingComplete(): void {
    const isLoaded = this.isLookupsLoaded && this.isHospitalsLoaded;
    if (isLoaded) {
      this.isLoading = false;
      this.initializePageState();
    }
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistReport',
      value: this.buildWaitlistPageState(),
    });
    // Clear any previous results
    this.$store.commit('waitlist/setWaitlistQueryResult', null);
  }

  // Array of transplant program abbreviation codes included in the filter options
  get transplantProgramsIncluded(): string[] {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistTransplantProgramOptions;
    // Map lookup format (code/value) to tag autocomplete format (text)
    const codes: string[] = options.map((option: GenericCodeValue): string => {
      return option.code;
    });
    return codes;
  }

  get getColumnDefaults(): string[] {
    return [
      'client_id',
      'first_name',
      'last_name',
      'transplant_hospital_mrn',
      'blood_type',
      'parsed_cluster',
      'parsed_donor_type',
      'medical_status_code',
      'listing_date',
      'parsed_wait_days',
      'parsed_wait_years',
      'parsed_hold_medical',
      'parsed_hold_serum',
      'parsed_suspended',
      'transplant_hospital_identifier',
      'smc_score',
      // TODO: Exception Diseases
      'vad_implant_date',
      'parsed_vad_indication',
      'parsed_vad_side',
      'medical_status_secondary_code',
      'allocation_points',
      'cumulative_cpra',
      'dialysis_start_date',
      'first_infusion_date',
    ];
  }

  private buildWaitlistPageState(): WaitlistPageState {
    // If user is Recipient Coordinator, Organ Type and Transplant Program default to user attributes and cannot be changed
    let organ: string|null = null;
    let transplantPrograms: string[] = [];
    let donorType: string|null = null;
    if (this.isCoordinator) {
      // Transplant Program / Hospital
      transplantPrograms = this.waitlistTransplantProgramOptions.map((option: GenericCodeValue): string => {
        return option.code;
      });
      // Organ Codes
      const organCode = this.getUserOrganCodes.length > 0 ? this.getUserOrganCodes[0] : null;
      organ = organCode ? organCode.toString() : null;
      // Donor Type
      const isLivingDonorApplicable = organCode == null || LIVING_DONOR_APPLICABLE_ORGAN_CODES.includes(organCode);
      donorType = isLivingDonorApplicable ? null : 'deceased';
    }

    // get selected columns from user preferences or defaults
    const columns = this.getColumnPreferences('waitlistReportColumns', this.getColumnDefaults);

    // TODO: Blood Type can have multiple values, and defaults to All values: A, B, AB, and O
    const result = {
      filters: {
        organ,
        donorType,
        cluster: true,
        medicalHold: true,
        serumHold: true,
        suspended: true,
        bloodTypes: [],
        transplantPrograms,
        // variables for tag inputs (will be re-generated based on above)
        bloodTypeInput: '',
        bloodTypeTags: [],
        transplantProgramInput: '',
        transplantProgramTags: [],
      },
      selectedColumns: columns
    };
    return result;
  }

  // Disable the Preferred Donor Type filter for organs that Living Donor is not applicable
  get disablePreferredDonorTypeFilter(): boolean {
    if (!this.editState || !this.editState.filters) {
      return false;
    }
    const organCode = this.editState.filters.organ != null ? Number(this.editState.filters.organ) : null;
    const isLivingDonorApplicable = organCode == null || LIVING_DONOR_APPLICABLE_ORGAN_CODES.includes(organCode);
    return !isLivingDonorApplicable;
  }

  // When organ filter changes, we may need to update the Preferred Donor Type filter
  private onOrganFilterChange(event: any): void {
    if (!this.editState || !this.editState.filters) {
      return;
    }
    // Update the default value of Preferred Donor Type
    const organCode = this.editState.filters.organ != null ? Number(this.editState.filters.organ) : null;
    const isLivingDonorApplicable = organCode == null || LIVING_DONOR_APPLICABLE_ORGAN_CODES.includes(organCode);
    const donorType = isLivingDonorApplicable ? null : 'deceased';
    Vue.set(this.editState.filters, 'donorType', donorType);
  }

  // Generate display text for "Preferred Donor Type" field based on waitlist recipient entry
  private parseDonorType(recipient: any): string|null {
    const acceptDeceased = recipient?.donor_acceptability?.deceased_donor || false;
    const acceptLiving = recipient?.donor_acceptability?.living_donor || false;
    if (acceptDeceased && acceptLiving) {
      return this.$t('both').toString();
    } else if (acceptDeceased) {
      return this.$t('deceased').toString();
    } else if (acceptLiving) {
      return this.$t('living').toString();
    }
    return null;
  }

  get waitlistGridRows(): any[] {
    if (!this.waitlistQueryResult) {
      return [];
    }
    const recipients = this.waitlistQueryResult.waitlisted_recipients || [];
    const organCode = this.waitlistQueryResult.filters.organ;
    const rows = recipients.map((recipient: any): any => {
      return {
        // unparsed data
        ...recipient,
        // common fields
        parsed_wait_days: `${recipient.wait_days} day${recipient.wait_days === 1 ? '' : 's'}`,
        parsed_wait_years: `${Number(recipient.wait_days / 365).toFixed(1)} year${recipient.wait_days > 365 ? 's' : ''}`,
        parsed_hold_serum: (recipient.on_hold_serum_hla_antibody) ? this.$t('yes').toString() : this.$t('no').toString(),
        parsed_hold_medical: (recipient.on_hold_medical) ? this.$t('yes').toString() : this.$t('no').toString(),
        parsed_suspended: (recipient.suspended) ? this.$t('yes').toString() : this.$t('no').toString(),
        parsed_donor_type: this.parseDonorType(recipient) || '-',
        // TODO: Cluster (Text) Additional organ or organs that form a cluster with the selected organ
        parsed_cluster: recipient.cluster_organ_code ? this.clusterOrganCodeDisplayValue(recipient.organ_code, recipient.cluster_organ_code) : '-',
        // organ-specific fields
        parsed_vad_indication: recipient.vad_indication_code == null ? '-' : this.lookupValue(recipient.vad_indication_code.toString(), 'vad_indication') || '-',
        parsed_vad_side: recipient.vad_side == null ? '-' : this.lookupValue(recipient.vad_side_code(), 'vad_side') || '-',
      };
    });
    return rows;
  }

  get waitlistResultSummary(): WaitlistResultSummary {
    if (!this.waitlistQueryResult) {
      return {};
    }
    const summary = this.waitlistQueryResult.summary || {};
    return {
      // Results Summary
      totalMatching: summary.matching_filters,
      totalClustered: summary.clustered,
      totalMedicalHold: summary.hold_medical,
      totalSerumHold: summary.hold_serum,
      totalSuspended: summary.suspended,
    };
  }

  private sortWaitTime(aValue: string, bValue: string, column: any, aRow: any, bRow: any): number {
    // Fetch unparsed wait days number from row data
    const aNumber = aRow.wait_days || 0;
    const bNumber = bRow.wait_days || 0;
    // Sort numerically
    const result = Math.sign(aNumber - bNumber);
    return result;
  }

  private sortMedicalStatus(aValue: string, bValue: string, column: any, aRow: any, bRow: any): number {
    // Fetch unparsed status code from row data
    const aCode: string = aRow.medical_status_code || '0';
    const bCode: string = bRow.medical_status_code || '0';
    // Lookup medical status priority
    const organCode = this.waitlistQueryResult.filters.organ;
    const aPriority = this.medicalStatusPriority(this.organLookup, organCode, aCode);
    const bPriority = this.medicalStatusPriority(this.organLookup, organCode, bCode);
    // Sort numerically with preference to 'lower' priorities
    const result = Math.sign(bPriority - aPriority);
    return result;
  }

  private buildTextFilter(): any {
    // Generic string filter
    return {
      enabled: true,
      placeholder: ' ',
    };
  }

  private buildDateFilter(): any {
    // Generic string filter
    const formatDate = this.formatDateFromDateTime;
    return {
      enabled: true,
      placeholder: ' ',
      // should return true if data matches the filterString, otherwise false
      filterFn: function(data?: string|null, filterString?: string|null): boolean {
        if (data == null) {
          return false;
        }
        if (filterString == null) {
          return true;
        }
        const parsedDate = formatDate(data);

        // Parse numeric client_id as if it were a string
        const filterRegexp = new RegExp(filterString);
        const result = filterRegexp.test(parsedDate);
        return result;
      },
    };
  }

  private buildNumberFilter(): any {
    return {
      enabled: true,
      placeholder: ' ',
      // should return true if data matches the filterString, otherwise false
      filterFn: function(data?: number|null, filterString?: string|null): boolean {
        if (data == null) {
          return false;
        }
        if (filterString == null) {
          return true;
        }
        // Parse numeric client_id as if it were a string
        const dataString = data.toString();
        const result = dataString.indexOf(filterString) > -1;
        return result;
      },
    };
  }

  private buildBloodTypeFilter(): any {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistBloodTypeOptions;
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.value,
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  get waitlistBloodTypeOptionsAsLookup(): { text: string }[] {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistBloodTypeOptions;
    // Map lookup format (code/value) to tag autocomplete format (text)
    const autocompleteOptions: { text: string }[] = options.map((option: GenericCodeValue): { text: string } => {
      return {
        text: option.code,
      };
    });
    return autocompleteOptions;
  }

  // Limit dropdown options to values that match input
  get filteredWaitlistBloodTypeAutocompleteOptions(): { text: string }[] {
    const options = this.waitlistBloodTypeOptionsAsLookup || [];
    const input = this.editState.filters.bloodTypeInput;
    if (!this.editState || !this.editState.filters || !input) {
      return options;
    }
    const filtered = options.filter((option: { text: string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  get waitlistTransplantProgramOptionsAsLookup(): { text: string }[] {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistTransplantProgramOptions;
    // Map lookup format (code/value) to tag autocomplete format (text)
    const autocompleteOptions: { text: string }[] = options.map((option: GenericCodeValue): { text: string } => {
      return {
        text: option.code,
      };
    });
    return autocompleteOptions;
  }

  // Limit dropdown options to values that match input
  get filteredWaitlistTransplantProgramAutocompleteOptions(): { text: string }[] {
    const options = this.waitlistTransplantProgramOptionsAsLookup || [];
    const input = this.editState.filters.transplantProgramInput;
    if (!this.editState || !this.editState.filters || !input) {
      return options;
    }
    const filtered = options.filter((option: { text: string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  getAllTransplantPrograms(code: number): any {
    const programs = this.getTransplantProgramsByOrgan(code);
    return programs.map((item: any) => {
      return item.program_identifier;
    });
  }

  get getAllBloodTypes(): any {
    const types: GenericCodeValue[] = this.waitlistBloodTypeOptions;
    return types.map((item: any) => {
      return item.code;
    });
  }

  private buildProgramFilter(): any {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistTransplantProgramOptions;
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.code,  // Use program identifier code in the table
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  private buildBooleanFilter(): any {
    // Filters the 'Yes' and 'No' that boolean fields are mapped
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems: [this.$t('yes'), this.$t('no')],
    };
  }

  private buildDonorTypeFilter(): any {
    // Match options from query filters
    const options: GenericCodeValue[] = this.waitlistDonorTypeOptions;
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.value,
        text: option.value,
      };
    });
    const result = {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
    return result;
  }

  private buildMedicalStatusFilter(): any {
    // Match options from query filters
    const organCode: number|undefined = this.waitlistOrganCodeNumeric != null ? this.waitlistOrganCodeNumeric : undefined;
    const options: GenericCodeValue[] = this.medicalStatusLookup(true, this.organLookup, organCode);
    // Ensure there is at least one option in the dropdown, to guarantee consistent rendering of the filter when no organ selected
    if (options.length === 0) {
      options.push({ code: OrganWaitlistMedicalStatusValue.OnHold, value: OrganWaitlistMedicalStatusValue.OnHold });
    }
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.code,
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  private buildSecondaryMedicalStatusFilter(): any {
    // Match options from query filters
    const organCode: number|undefined = this.waitlistOrganCodeNumeric != null ? this.waitlistOrganCodeNumeric : undefined;
    const options: GenericCodeValue[] = this.secondaryMedicalStatusLookup(this.organLookup, organCode);
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.code,
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  private buildVadIndicationFilter(): any {
    // Match options from query filters
    const options: NumericCodeValue[] = this.vadIndicationLookup;
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: NumericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.value,
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  private buildVadSideFilter(): any {
    // Match options from query filters
    const options: GenericCodeValue[] = this.vadSideLookup;
    // Map lookup format (code/value) to filter option format (value/text)
    const filterDropdownItems: { value: any; text: string }[] = options.map((option: GenericCodeValue): { value: any; text: string } => {
      return {
        value: option.code,
        text: option.value,
      };
    });
    return {
      enabled: true,
      placeholder: this.$t('all'),
      filterDropdownItems,
    };
  }

  get waitlistGridColumns(): any[] {
    const result: any[] = [
      // All organ types
      { label: this.$t('client_id'), field: 'client_id', type: 'number', filterOptions: this.buildNumberFilter() },
      { label: this.$t('first_name'), field: 'first_name', filterOptions: this.buildTextFilter() },
      { label: this.$t('last_name'), field: 'last_name', filterOptions: this.buildTextFilter() },
      { label: this.$t('mrn'), field: 'transplant_hospital_mrn', filterOptions: this.buildTextFilter() },
      { label: this.$t('abo'), field: 'blood_type', filterOptions: this.buildBloodTypeFilter() },
      { label: this.$t('medical_status'), field: 'medical_status_code', sortFn: this.sortMedicalStatus, filterOptions: this.buildMedicalStatusFilter() },
      { label: this.$t('list_date'), field: 'listing_date', type: 'date', formatFn: this.formatDateFromDateTime, filterOptions: this.buildDateFilter() },
      { label: this.$t('wait_time_days'), field: 'parsed_wait_days', sortFn: this.sortWaitTime, filterOptions: this.buildTextFilter() },
      { label: this.$t('wait_time_years'), field: 'parsed_wait_years', sortFn: this.sortWaitTime, filterOptions: this.buildTextFilter() },
      { label: this.$t('medical_hold'), field: 'parsed_hold_medical', filterOptions: this.buildBooleanFilter() },
      { label: this.$t('serum_hold'), field: 'parsed_hold_serum', filterOptions: this.buildBooleanFilter() },
      { label: this.$t('suspended'), field: 'parsed_suspended', filterOptions: this.buildBooleanFilter() },
      { label: this.$t('program'), field: 'transplant_hospital_identifier', filterOptions: this.buildProgramFilter() },
    ];
    if (this.showDonorTypeColumn) {
       result.splice(5, 0, { label: this.$t('preferred_donor_type'), field: 'parsed_donor_type', filterOptions: this.buildDonorTypeFilter() });
    }
    // Donor Type included only when
    if (this.showClusteredColumn) {
      result.splice(5, 0, { label: this.$t('cluster'), field: 'parsed_cluster', filterOptions: this.buildTextFilter() });
    }
    // Organ-specific columns
    this.activeOrganColumns.forEach((column: any) => {
      result.push(column);
    });
    return result;
  }

  get organSpecificColumns(): { [key: string]: any[] } {
    const result = {
      '1': [
        // Liver
        { label: this.$t('smc_score'), field: 'smc_score', type: 'number', filterOptions: this.buildNumberFilter() },
        // TODO: Exception Diseases
      ],
      '2': [
        // Heart
        { label: this.$t('vad_implant_date'), field: 'vad_implant_date', type: 'date', formatFn: this.formatDateFromDateTime , filterOptions: this.buildDateFilter() },
        { label: this.$t('vad_indication'), field: 'parsed_vad_indication', filterOptions: this.buildVadIndicationFilter() },
        { label: this.$t('vad_side'), field: 'parsed_vad_side', filterOptions: this.buildVadSideFilter() },
        { label: this.$t('secondary_status'), field: 'medical_status_secondary_code', sortFn: this.sortMedicalStatus, filterOptions: this.buildSecondaryMedicalStatusFilter() },
      ],
      '3': [
        // Kidney
        { label: this.$t('allocation_points'), field: 'allocation_points', type: 'decimal', filterOptions: this.buildNumberFilter() },
        { label: this.$t('cumulative_cpra'), field: 'cumulative_cpra', type: 'number', filterOptions: this.buildNumberFilter() },
        { label: this.$t('dialysis_start_date'), field: 'dialysis_start_date', type: 'date', formatFn: this.formatDateFromDateTime, filterOptions: this.buildDateFilter() },
      ],
      '6.5': [
        // Pancreas (for islets)
        { label: this.$t('infusion_1_date'), field: 'first_infusion_date', type: 'date', sortable: true, dateInputFormat: Format(this.getDateFormat).DATE_TIME_ISO, dateOutputFormat: Format(this.getDateFormat).DISPLAY_DATE, filterOptions: this.buildDateFilter() },
      ],
    };
    return result;
  }

  // Donor Type included when Donor Type filter is set to: "All" or "Deceased or Living"
  get showDonorTypeColumn(): boolean {
    let donorType: string|null = null;
    if (this.waitlistQueryResult && this.waitlistQueryResult.filters) {
      donorType = this.waitlistQueryResult.filters.donor_type;
    } else if (this.editState && !!this.editState.filters) {
      donorType = this.editState.filters.donorType;
    }
    const isAll = donorType === 'any' || donorType == null || donorType === '';
    const isBoth = donorType === 'both';
    const result = isAll || isBoth;
    return result;
  }

  // Cluster included only when “Include Cluster Patients” is selected.
  get showClusteredColumn(): boolean {
    let result = false;
    if (this.waitlistQueryResult && this.waitlistQueryResult.filters) {
      result = this.waitlistQueryResult.filters.cluster;
    } else if (this.editState && !!this.editState.filters) {
      result = this.editState.filters.cluster;
    }
    return result;
  }

  get waitlistOrganCode(): string|null {
    let organCode: string|null = null;
    if (this.waitlistQueryResult && this.waitlistQueryResult.filters) {
      const rawOrganCode: number = this.waitlistQueryResult.filters.organ;
      organCode = rawOrganCode.toString();
    } else if (this.editState && !!this.editState.filters) {
      organCode = this.editState.filters.organ;
    }
    return organCode;
  }

  get waitlistOrganCodeNumeric(): number|null {
    let organCode: number|null = null;
    if (this.waitlistQueryResult && this.waitlistQueryResult.filters) {
      organCode = this.waitlistQueryResult.filters.organ;
    } else if (this.editState && !!this.editState.filters) {
      const rawOrganCode: string|null = this.editState.filters.organ;
      const numericOrganCode = rawOrganCode != null ? parseInt(rawOrganCode) : null;
      organCode = numericOrganCode != null && !isNaN(numericOrganCode) ? numericOrganCode : null;
    }
    return organCode;
  }

  get activeOrganColumns(): any[] {
    if (this.waitlistOrganCode === null) {
      return [];
    }
    const result = this.organSpecificColumns[this.waitlistOrganCode] || [];
    // TOOD: include Kidney columns if any waitlisted recipieny journey is a Kidney-Pancreas journey
    // TODO: also show columns for clustered organ types found in ANY waitlisted recipient journey in the response
    return result;
  }

  private organSpecificSortOrder(organCode: string): any[] {
    let result: any[] = [];
    switch (organCode) {
      case '1':
        // Liver
        result = [
          { field: 'parsed_suspended', type: 'asc' },
          { field: 'parsed_hold_medical', type: 'asc' },
          { field: 'medical_status_code', type: 'desc' },
          { field: 'blood_type', type: 'asc' },
          { field: 'smc_score', type: 'desc' },
          { field: 'parsed_wait_days', type: 'desc' },
          { field: 'parsed_wait_years', type: 'desc' },
        ];
        break;
      case '2':
      case '4':
        // Heart and Lung
        result = [
          { field: 'parsed_suspended', type: 'asc' },
          { field: 'parsed_hold_medical', type: 'asc' },
          { field: 'medical_status_code', type: 'desc' },
          { field: 'blood_type', type: 'asc' },
          { field: 'parsed_wait_days', type: 'desc' },
          { field: 'parsed_wait_years', type: 'desc' },
        ];
        break;
      case '3':
        // Kidney
        result = [
          { field: 'parsed_suspended', type: 'asc' },
          { field: 'parsed_hold_medical', type: 'asc' },
          { field: 'medical_status_code', type: 'desc' },
          { field: 'blood_type', type: 'asc' },
          { field: 'allocation_points', type: 'desc' },
        ];
        break;
      case '6':
      case '6.5':
        // Pancreas (whole) and Pancreas (for islets)
        result = [
          { field: 'parsed_suspended', type: 'asc' },
          { field: 'parsed_hold_medical', type: 'asc' },
          { field: 'medical_status_code', type: 'desc' },
          { field: 'blood_type', type: 'asc' },
        ];
        break;
      case '7':
        // Small Bowel
        result = [
          { field: 'parsed_suspended', type: 'asc' },
          { field: 'parsed_hold_medical', type: 'asc' },
          { field: 'medical_status_code', type: 'asc' },
          { field: 'blood_type', type: 'asc' },
          { field: 'parsed_wait_days', type: 'desc' },
          { field: 'parsed_wait_years', type: 'desc' },

        ];
        break;
    }
    return result;
  }

  get waitlistInitialSortBy(): any[] {
    if (this.waitlistOrganCode === null) {
      return [];
    }
    const initialSort = this.organSpecificSortOrder(this.waitlistOrganCode);
    // Filter out any unselected columns
    let result: any[];
    if (this.editState && this.editState.selectedColumns) {
      const selected = this.editState.selectedColumns;
      result = initialSort.filter((sort: any) => {
        return selected.includes(sort.field);
      });
    } else {
      result = initialSort;
    }
    // TOOD: Kidney-Pancreas and Clusters
    return result;
  }

  get filteredColumns(): any[] {
    if (!this.editState || !this.editState.selectedColumns) {
      return this.waitlistGridColumns;
    }
    const selectedFields = this.editState.selectedColumns;
    const selectedColumns = this.waitlistGridColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  get waitlistGridTableConfig(): TableConfig {
    const data = this.waitlistGridRows || [];
    const columns = this.filteredColumns || [];
    const empty = this.waitlistQueryResult ? this.$t('no_waitlisted_recipients_match').toString() : this.$t('the_form_above').toString();
    return {
      data,
      columns,
      empty,
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 25,
        mode: 'records',
        perPageDropdown: [10, 25, 100],
        dropdownAllowAll: true,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      },
      sortOptions: {
        enabled: true,
        initialSortBy: this.waitlistInitialSortBy,
      }
    };
  }

  /*
    Note: here we are mapping the Organ Codes from numbers to strings. This is due to an issue with disabled select
    elements. When the option codes are numbers and the Select Input is disabled, its selected value is not rendered.
  */
  get waitlistOrganOptions(): GenericCodeValue[] {
    // NOTE: here we assume that the Waitlist page should only support organs with non-expired entry in the organ lookup
    const numericOptions = this.nonExpiredOrganOptions('single');
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};
    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    const filteredOrganOptions = userOrganCodes.length > 0 ? numericOptions.filter((organ) => userOrganCodes.includes(organ.code)) : numericOptions;
    const stringOptions = filteredOrganOptions.map((numeric: NumericCodeValue): GenericCodeValue => {
      const code: string = numeric.code.toString();
      return {
        code,
        value: numeric.value,
      };
    });
    return stringOptions;
  }

  get waitlistTransplantProgramOptions(): GenericCodeValue[] {
    if (!this.hospitals) {
      return [];
    }

    const transplantPrograms = this.hospitals.filter((hospital: Hospital) => {
      return this.regionIncludesProvinceCode(hospital);
    });

    const filterBy = this.user.hospital_organ_codes || {};
    const filteredTransplantPrograms = Object.keys(filterBy).length > 0 ? transplantPrograms.filter((tp) => Object.keys(filterBy).includes(tp?._id?.$oid)) : transplantPrograms;

    const options = filteredTransplantPrograms.map((transplantProgram: Hospital): GenericCodeValue => {
      const code = this.programIdentifier(transplantProgram) || 'UNKNOWN';
      const value = transplantProgram.hospital_name_info ? transplantProgram.hospital_name_info.name : '-';
      return {
        code,
        value,
      };
    });
    return options;
  }

  get waitlistDonorTypeOptions(): GenericCodeValue[] {
    return [
      { code: "deceased", value: this.$t('deceased').toString() },
      { code: "living", value: this.$t('living').toString() },
      { code: "both", value: this.$t('both').toString()},
    ];
  }

  get waitlistBloodTypeOptions(): BloodType[] {
    const allOptions = this.bloodTypeLookup || [];
    const filtered = allOptions.filter((option: BloodType) => {
      return this.bloodTypesIncluded.includes(option.code);
    });
    return filtered;
  }

  private programIdentifier(hospital?: Hospital|null): string|null {
    if (hospital == null) {
      return null;
    }
    const programIdentifier = hospital.program_identifier;
    const abbreviation = hospital?.hospital_name_info?.abbreviation;
    return programIdentifier || abbreviation || null;
  }

  private hospitalName(hospital?: Hospital|null): string|null {
    if (hospital == null) {
      return null;
    }
    const name = hospital?.hospital_name_info?.name;
    const abbreviation = hospital?.hospital_name_info?.abbreviation;
    const user = hospital.updated_by;
    return name || abbreviation || user || null;
  }

  private buildWaitlistQuery(): WaitlistQuery {
    if (!this.editState || !this.editState.filters) {
      return { organ_code: '0' };
    }
    const filters = this.editState.filters;
    const donor_type = !this.disablePreferredDonorTypeFilter ? filters.donorType || undefined : undefined;
    const result = {
      organ_code: filters.organ || '0', // string type because it is a route parameter
      program_identifier: filters.transplantPrograms,
      blood_type: filters.bloodTypes,
      cluster: filters.cluster,
      hold_medical: filters.medicalHold,
      hold_serum: filters.serumHold,
      suspended: filters.suspended,
      donor_type,
    };
    return result;
  }

  public generateWaitlistReport(): void {
    // Show saving notification
    const saveToolbar = this.$refs.generateWaitlistReport as SaveToolbar;
    saveToolbar.startSaving();
    // Submit query
    const waitlistQuery = this.buildWaitlistQuery();
    this.$store.dispatch('waitlist/submitWaitlistQuery', waitlistQuery).then((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }

  private buildWaitlistPrint(): any {
    const filters = this.editState.filters;
    const donor_classification = filters.donorType || "both";
    const organ_code = Number(filters.organ) || 0; // string type because it is a route parameter

    let donor_living = null;
    let donor_deceased = null;

    // check organ code against approved living donor codes
    const isLivingDonorApplicable = LIVING_DONOR_APPLICABLE_ORGAN_CODES.includes(organ_code);

    if (!isLivingDonorApplicable) {
      // if true set report parameters
      donor_living = this.$t('no');
      donor_deceased = this.$t('yes');
    } else {
      // for anything else use living/deceased based on donor clasification
      switch(donor_classification) {
        case 'both':
          donor_living = this.$t('yes');
          donor_deceased = this.$t('yes');
          break;
        case 'living':
          donor_living = this.$t('yes');
          donor_deceased = this.$t('no');
          break;
        case 'deceased':
          donor_living = this.$t('no');
          donor_deceased = this.$t('yes');
          break;
        default:
          donor_living = this.$t('yes');
          donor_deceased = this.$t('yes');
          break;
      }
    }

    // get hospitals approved list
    const allowedHospitals = this.getAllTransplantPrograms(organ_code);
    let hospitals: any = [];
    if (filters.transplantPrograms.length > 0) {
      // if given hospitals, filter against approved list
      hospitals = allowedHospitals.filter((item: any) => filters.transplantPrograms.includes(item));
    } else {
      // if chosen any, use approved list
      hospitals = this.getAllTransplantPrograms(organ_code);
    }

    const result = {
      organ_code: organ_code,
      waitlist_organ_report: {
        blood_type: filters.bloodTypes.length > 0 ? filters.bloodTypes : this.getAllBloodTypes,
        hospital: hospitals,
        accept_living_donor: donor_living,
        accept_deceased_donor: donor_deceased
      }
    };
    return result;
  }
}
